//	BREAKPOINTS
$mobile: 23em; 				//	~= 375	iPhone 6/7/8
$large_mobile: 25em; 		//	~= 414	iPhone 6/7/8 Plus
$small_tablet: 48em;		//	768			Tablet
$tablet: 64em;				//	1024		Large Tablet OR Landscape Tablet
$small_desktop: 80em;		//	1280		Notebooks
$desktop: 90em;				//	1440		Large Notebooks	OR Desktops
$large_desktop: 120em;	    //	1920		Large Desktops

//  COLORS
$white: #fff;
$black: #000;
$light_gray: #efefef;
$glitter: #E5EBEF;
$another_gray: #EFF0F2;
$gray_bg: #EFF0F2; //old #F4F5F6
$lavender: #c7c8c9;
$cardinal: #C9243F;
$amaranth_red: #da1a31;
$midnight_blue: #013963;
$dark_midnight_blue: #00365f;
$blue: #1D87FF;
$cerulean_blue: #0C496F;
$another_blue: #4c728f;
$yellow: #FFEA00;
$random_gray: #CECECE;
$bg_blue: #0089ce;
$medium_gray: #cccccc;
$dark_gray: #999;
$darker_gray: #4b4b4b;
$new_gray: #acacac;

$yellow_highlight: rgb(255, 255, 214);

$old_green: #a2b528;
$light_green: #415e18;
$purple: #4A124C;
$light_blue: #158EE0;

//  MISC

//	FONT WEIGHTS
$light: 300;
$regular: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;

//  WIDTH
$small_width: 300px;