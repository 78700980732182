.select_box {
  border: 1px solid black;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 115px;
  min-width: 100px;
  span {
    color: #6b6b6b;
    position: absolute;
    bottom: 15px;
  }

  i {
    font-size: 50px;
    color: #6b6b6b;
    font-weight: 900;
    display: table;
    margin-top: 25%;
  }
}