@use "../utils/variables" as utils-variables;

.create_input {
  margin-top: 5px;
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="number"],
  input[type="password"],
  select {
    font-family: "National", sans-serif;
    border-radius: 1.5px;
    font-size: 18px;
    height: 43px;
    width: 225px;
    border: solid 1px #666666;
    padding: 5px 10px;
  }

  &.no-margin {
    margin-top: 0px;
  }

  &.fit {

    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: auto;
    }
  }
  &.small {
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: 75px;
    }
  }

  &.medium-small {

    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: 150px;
    }
  }

  &.medium {
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: 180px;
    }
  }

  &.medium-big {
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: 250px;
    }
  }

  &.almost-big {
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: 300px;
    }
  }

  &.big {
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      width: 350px;
    }
  }
  &.is-error {
    input[type="text"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    input[type="password"],
    select {
      border: solid 1px utils-variables.$amaranth_red;
    }
  }
  
  &.pedding-lr-10px {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  label {
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 7px;
    font-family: National, sans-serif;
    font-size: 14px;
    font-weight: 600;
    &.disable {
      margin-top: -10px;
      font-family: National, sans-serif;
      font-size: 18px;
    }
    &.terms {
      font-family: National, sans-serif;
      font-size: 16px;
    }
  }
  span.error {
    color: red;
    font-size: 15px;
    line-height: 15px;
    font-family: National, sans-serif;
    margin-top: 8px;
    max-width: 225px;
    &.small {
      max-width: 75px;
    }
    &.big {
      max-width: 400px;
    }
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    opacity: 0;
    width: 100%;
  }

  input[type="date"]:before {
    content: attr(value);
    width: 100%;
  }
}


.create_input.responsive-on-tablet {
  @media only screen and (max-width: utils-variables.$small_tablet) {
    width: 100%;
    margin-top: 15px;
    
    &.small {
      input[type="text"],
      input[type="email"],
      input[type="date"],
      input[type="number"],
      input[type="password"],
      select {
        width: 100%;
      }
    }

    &.medium {
      input[type="text"],
      input[type="email"],
      input[type="date"],
      input[type="number"],
      input[type="password"],
      select {
        width: 100%;
      }
    }
  }
}
