@use "../utils/variables";

.radio_box {
  border: solid 1px #666666;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 112.5px;
  span {
    color: #6b6b6b;

    &.clicked {
      color: white;
    }
  }
  &.clicked {
    background-color: variables.$amaranth_red;
    border: none;
  }
}