@use "utils/mixins";
@use "utils/variables" as utils-variables;
@use "variables";

.logo-image-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.bold-500{
  font-weight: 500;
}

.gray-bar {
  height: 15px;
  margin: 0;
  border: none;
  background-color: #e8e8e8;
}

.text-align-center {
  text-align: center;
}

.receipt-div {
  width: 450px;
  align-self: center;
}

.logo-image {
  max-height: 100px;
  margin: auto;
}

.club-name-header {
  margin-top: 25px;
  font-weight: utils-variables.$bold;
  font-size: 35px;
}

.grey-bottom-line {
  border-bottom: 2px solid #F6F6F6;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.info-box {
  padding: 0px 20px;
  width: 400px;

  p {
    font-size: 15px;
    line-height: 18px;
  }
  
  @media only screen and (max-width: utils-variables.$small_tablet) {
    width: 100%;
    padding: 0px 5px;
  }
}

.info-box-association {
  p {
    font-size: 15px;
    line-height: 18px;
  }
}

.flex-end {
  align-items: flex-end;
  
  @media only screen and (max-width: utils-variables.$small_tablet) {
    width: 100%;
  }
}

.flex-start {
  align-items: flex-start;
}

.header-sentry-logo {
  display: block;

  height: 50px;
  width: auto;

  margin: 0 0 0 auto;

  @include mixins.breakpoint(utils-variables.$small_tablet) {
    margin: 0 0 0 32px;
  }
}

.usga_logo {

  z-index: -5;
  @include mixins.breakpoint() {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    color: utils-variables.$dark_midnight_blue;

    font-size: 13px;
    line-height: 12px;
    font-weight: utils-variables.$bold;

    text-transform: uppercase;

    @include mixins.user-select(none);

    height: 100%;

    position: relative;
    cursor: pointer;

  }

  @include mixins.breakpoint(utils-variables.$small_desktop) {

    font-size: 16px;
    line-height: 16px;

  }

  &::before {

    @include mixins.breakpoint() {

      content: '';
      display: block;

      width: 80px;
      height: 24px;

      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center;

      background-size: contain;

      background-image: url('../assets/img/logos/logo_usga.svg');

      margin: 0 8px 0 0;

    }

    @include mixins.breakpoint(utils-variables.$small_desktop) {

      width: 90px;
      height: 32px;

    }

  }

}

.footer__container {
  @include mixins.breakpoint() {
    height: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  @include mixins.breakpoint(utils-variables.$small_desktop) {
    margin: 0 auto;
  }
}

.grey_background {
  background-color: #F5F5F5;
  padding: 10px 20px 10px 20px;
  border: #F5F5F5 solid 1px;
  border-radius: 5px;
}

.align-items-end {
  align-items: flex-end;
}

.payment_container {
  align-self: center;
  width: 450px;

  @media only screen and (max-width: utils-variables.$small_tablet) {
    width: 370px;
  }
  
  
  .MuiFormControlLabel-label{
    margin-top: 4px;
    line-height: 1.3;
  }
  
  .MuiCheckbox-root{
    padding: 0px;
      margin-top: auto;
      margin-bottom: auto;
  }
}

.width-700-center {
  width: 700px;
  margin-left: calc((100% - 684px) / 2);
  margin-right: calc((100% - 684px) / 2);

  @media only screen and (max-width: utils-variables.$small_tablet) {
    width: inherit;
    margin-left: auto;
    margin-right: auto;
  }
}

.agree_terms label {
  display: inline;
  padding-left: 10px;
  line-height: 1.3;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.create-ghin-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.payment_inputs {
  font-size: 50;
}

.small_bottom_margin {
  margin-bottom: 3px;
}

.med_bottom_margin {
  margin-bottom: 12px;
}

.medium_bottom_margin {
  margin-bottom: 15px;
}

.row_bottom_margin {
  margin-bottom: 16px;
}

.large_bottom_margin {
  margin-bottom: 30px;
}

.small_top_margin {
  margin-top: 3px;
}

.no_top_margin {
  margin-top: 0 !important;
}

.no_bottom_margin {
  margin-bottom: 0 !important;
}

.no_right_margin {
  margin-right: 0 !important;
}

.no_left_margin {
  margin-left: 0 !important;
}
.bold {
  font-weight: bold;
}

.big-container{
  margin-top: 4vh;
  width: 339px;
  text-align: center;
}

.big-text {
  font-size: 20px !important;
}

.x-large-text {
  font-size: 25px !important;
}

.title {
  font-size: 35px !important;
}

.medium-title {
  font-size: 30px !important;
}
.align-text-center {
  text-align: center;
}

.left-align {
  text-align: left;
}

.filler {
  flex-grow: 1;
}

.center_icon {
  vertical-align: middle;
}

.no_padding {
  padding: 0 !important;
}

.small_padding {
  padding: 20px;
}

.small_padding_2 {
  padding: 15px;
}

.small_padding_3 {
  padding: 15px 20px;
}

.small_padding_4 {
  padding: 15px 0px;
}

.small_right_padding{
  padding-right: 20px;
}

.small_icon {
  width: 15px !important;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.grey_border {
  border: rgb(245, 245, 245) solid 1px;
  border-radius: 5px;
}

.black_border {
  border: #c9c9c9 solid 1px;
  border-radius: 5px;
}

.multiple_choice_box {
  line-height: 40px;
  font-family: 'National', sans-serif;
  border-radius: 4px;
  text-align: center;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px;
  border-style: solid;
  border-color: gray;

  &:hover {
    background-color: rgb(188, 188, 188);
    cursor: pointer;
  }
}

.checkbox {
  &:hover {
    cursor: pointer;
  }
}

.input_field {
  max-height: 30px;
}

.focused_field_custom {
  border-color: aqua;
}

.center_vertical_margin {
  margin-top: auto;
  margin-bottom: auto;
}

.center_horizontal_margin {
  margin-left: auto;
  margin-right: auto;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
  max-width: 800px;
}

.header-text ul {
  list-style: disc;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
}

.mini-title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 7px;
  letter-spacing: 1px;
}

.custom-link {
  color: variables.$legend_light_blue;
  text-decoration: none;
  font-weight: 800;
  border: none;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;

  &.gray {
    color: #6B6B6B;
    text-decoration: underline;
  }
}

.error-text {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
}

.top-margin {
  margin-top: 20px !important;
}

.medium-top-margin {
  margin-top: 14px;
}

.tiny-top-margin {
  margin-top: 5px;
}

.date_input_container {
  height: auto !important;
  width: 262px !important;
    margin-left: -22.5px;
    scale: 83%;
    margin-top: -28px;
}

.date_input_container select {
  width: 100px !important;
  font-size: 13px !important;
  font-weight: bold;
  margin-top: 5px;
}

.date_input_container button {
  visibility: hidden;
}

.arial-font {
  font-family: Arial, Helvetica, sans-serif;
}

.font-24 {
  font-size: 24px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.gray-color {
  color: #333333;
}

.max-width-300 {
  max-width: utils-variables.$small_width;
}

.max-width-600 {
  max-width: utils-variables.$small_width + 300px;
  width: utils-variables.$small_width + 300px;
}

.max-width-600-fit {
  max-width: 600px;
  width: -webkit-fill-available;
}

.preferred-color-bottom-line {
  border-bottom: 1px solid var(--preferred-color);
  padding-bottom: 15px;
}

.preferred-color {
  color: var(--preferred-color);
}

.footer-card {
  border: 1px solid #d7d7d7;
  border-top: 6px solid var(--preferred-color);
  border-radius: 5px;
  padding: 15px;

}

.normal-line-height {
  line-height: normal;
}

.inline {
  display: inline-block;
}

.width-50 {
  width: 50%;
}

.club-details {
  width: 100%;
  line-height: initial;
  margin-bottom: 15px !important;
}

.block-container {
  display: block;
  justify-content: center;
  margin: 25px auto;

  strong p {
    line-height: 50px;
    font-size: xx-large;
  }

  @media only screen and (max-width: utils-variables.$small_tablet) {
    min-width: calc(100vw - 20px);
    margin-left: 10px;
    margin-right: 10px;

    strong p {
      font-size: x-large;
      text-align: center;
      line-height: 30px;
    }
  }
}

.inline-block {
  display: flex;
  align-items: center;
}

.semi-bold {
  font-weight: utils-variables.$semi-bold;
}

.margin-right {
  margin-right: 15px;
}

.no-decoration {
  text-decoration: none;
}

.center-text {
  text-align: center;
}

.payment-membership-title {
  max-width: 550px;
  width: -webkit-fill-available;
}

.medium-max-width {
  max-width: 650px !important;
}

.black-text {
  color: black;
}

.modal-grey-button {
  background-color: #AEAEAE !important;
  border-radius: 10px !important;
  font-size: 20px !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.auto-width {
  width: auto;
}

.create-account-box {
  max-width: 95vw;
  padding-bottom: 70px;
}
.mobile {
  display: none;

  @media (max-width: 470px) {
    display: block;
  }
}

.tablet {
  display: none;

  @media (min-width: 470px) {
    display: block;
  }
}

.hide {
  display: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: inherit;
}

html {
  overflow-y: scroll;
}

.important-full-width {
  width: 100% !important;
}

.flex-grow {
  // flex-grow: 1;
}

.membership_page {
  .MuiRadio-root {
    padding-top: 3px !important;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start !important;
  }

  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    max-width: 90%;
    white-space: initial;
    word-wrap: break-word;
  }

  .MuiFormControlLabel-root {
    max-width: 100%;
  }

  .MuiFormControl-root {
    max-width: 100%;
  }

  .panel_box {
    margin: auto;
    margin-top: 30px;
    padding: 1rem;
    background: #efefef;
    border: 1px solid #cccccc;
    width: 70%;
  }

  .panel_box p {
    margin: 0 !important;
  }

  p {
    font-size: 17px !important;
  }

  .unavailable-membership {
    p:nth-of-type(1), p:nth-of-type(2) {
      font-size: 18px !important;
    }
  }

  .disabled_memberships .MuiSvgIcon-root:nth-of-type(1) {
    background-color: #868686;
    border-radius: 50%;
    height: calc(1em - 4px);
    width: calc(1em - 4px);
    border: 1px solid #000;
  }

  .disabled_memberships .MuiSvgIcon-root:nth-of-type(1) path {
    fill: none;
  }
}

.margin-auto {
  margin: auto;
}
.password .error, .confirm-password .error {
  max-width: 150px !important;
}

.password .error, .confirm-password .error{
  margin-bottom: 10px !important;
}

.medium-margin-left {
  margin-left: 10px;
}

.medium-margin-bottom {
  margin-bottom: 8px !important;
}

.center-checkbox {
  padding-top: 9px;
}

.small-font{
  font-size: small;
}
.small-left-padding {
  padding-left: 11px;
}

.display-block {
  display: block !important;
}

.large-left-right-padding {
  padding-left: 27px;
  padding-right: 27px;
}

.x-large-left-right-padding {
  padding-left: 35px;
  padding-right: 35px;
}

.unset-flex {
  flex: unset !important;
}

.receipt-table {
  td, th {
    border: 1px solid #c9c9c9;
    padding: 10px;
  }

  th {
    text-align: left;
    width: 65%;
  }

  td {
    text-align: center;
  }

  tr:last-child th {
    text-align: right !important;
  }

  width: 100%;
  border-collapse: collapse;
}

.box-header {
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 95vw;
  justify-content: center;
  padding: 15px;
  background: #00365f;
  color: #fff;
  border-radius: 5px;
  margin-bottom: -10px;
  position: relative;
  font-size: 20px;
}

.white-container {
  background-color: white;
  width: calc(100% - 40px);
  margin: 20px 10px;
  padding: 20px 10px;
}

.logos-container {
  width: calc(100% - 20px);
  margin: 20px 10px;
  padding: 0px 10px;
  display: flex;
  margin-top: 5px;
}

.logos-container .white-logos-container {
  background-color: white;
  margin: 20px 10px;
  padding: 20px 10px;
  margin: auto;
}

.white-logos-container .logos {
  width: 100%;
  display: flex;
}

.logos .usga-logo {
  background-image: url('https://hcp2020-stage.s3.amazonaws.com/usga-logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 85px;
  min-height: 15px;
  margin-top: 15px;
}

.logos .usga-text {
  color: #00365f;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 700;
  padding-left: 10px;
  margin-top: 15px;
}

.logos .sentry-logo {
  background-image: url('https://hcp2020-stage.s3.amazonaws.com/sentry-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 50px;
  margin-left: 30px;
}

.download-app-container {
  width: calc(100% - 20px);
  margin: 20px 10px;
  padding: 20px 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
}

.download-app-container .white-mobile-container {
  background-color: white;
  margin: 20px 10px;
  padding: 20px 10px;
  padding-bottom: 0;
  margin: auto;
}

.white-mobile-container .title {
  text-align: center;
  font-size: 18px !important;
  margin-bottom: 10px;
  font-weight: bold;
}

.white-mobile-container .logos {
  width: 100%;
  display: flex;
}

.logos .apple-logo {
  background-size: contain;
  background-image: url('https://hcp2020-stage.s3.amazonaws.com/apple-store-logo.svg');
  background-repeat: no-repeat;
  width: 130px !important;
  height: 40px !important;
  margin-left: auto;
}

.logos .google-logo {
  background-size: contain;
  background-image: url('https://hcp2020-stage.s3.amazonaws.com/google-play-logo.svg');
  width: 140px !important;
  background-repeat: no-repeat;
  height: 40px;
  margin-left: 3px;
  margin-right: auto;
}

.margin-auto {
  margin: auto;
}

.space {
  height: 20px;
}

.small-space {
  height: 10px;
}

@media only screen and (min-width: 776px) and (max-width: 900px) {
  .white-mobile-container .title {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 776px) {
  .white-mobile-container .title {
    margin-bottom: 30px;
  }

  .download-app-container .white-mobile-container {
    padding: 10px 10px !important;
  }

  .logos-container .white-logos-container {
    padding: 0 10px !important;
  }

  .payment-membership-title {
    padding: 0;
  }

  .download-app-container .logos {
    margin-top: -20px !important;
  }

  .logos-container .logos {
    flex-wrap: wrap;
  }

  .usga-text {
    display: none;
  }

  .create-account-box {
    padding-top: 30px !important;
    padding-bottom: 45px !important;

    .create_input input {
      width: 100% !important;
    }
  }

  .logos {
    .google-logo {
      width: 100px !important;
      margin-left: 8px;
    }

    .apple-logo {
      width: 90px !important;
    }

    .usga-logo {
      width: 81px !important;
    }

    .sentry-logo {
      width: 83px !important;
    }
  }
}

.white-box {
  background: #fff;
  margin: auto;
  max-width: 95vw;
  margin-top: -30px;
  position: relative;
  border-radius: 5px;
  z-index: 99;
  border: 1px solid #dedede;

}

.tiny-padding-top {
  padding-top: 4px !important;
}

.break-word {
  word-wrap: break-word;
}

.existing-golfer-button {
  background-color: #AEAEAE !important;
  color: white !important;
  width: 35vw !important;
  max-width: 250px;
  min-width: 125px;
  border-radius: 10px !important;
}

.line-height-18 {
  line-height: 18px !important;
}

.grecaptcha-badge {
  display: none;
}

.page-title {
  font-weight: bold;
  background-color: #e8e8e8;
  padding: 13px;
  margin: 0 10px;
  text-align: center;
  width: 100%;
}

.pending-approval {
  color: red;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.codes-display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.codes-display-c {
  display: flex;
  flex-direction: column;
}

.original-fee {
  color: #6c757d;
  white-space: nowrap;
  font-style: italic;
  line-height: 15px;
  font-size: 0.9em;
}

.discount-code-text {
  color: red;
  font-style: italic;
  white-space: nowrap;
  font-size: 0.9em;
  top: 33px;
}

.promotion-code-text {
  color: red;
  white-space: nowrap;
  font-size: 0.9em;
  top: 33px;
}

.promo_code_col span.error {
  max-width: 300px!important;
}