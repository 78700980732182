@use "../utils/mixins";
@use "../utils/variables" as variables2;

.box-panel {
  background-color: variables2.$white;
  padding: 20px;
  &.center {
    justify-content: center;
    display: flex;
  }
  &.no-margins {
    padding: 0;
    padding: 0;
  }
}
@include mixins.breakpoint(variables2.$small_tablet) {
  .box-panel {
    padding: 30px 30px;
    &.no-margins {
      padding: 0;
      padding: 0;
    }
  }
}
@include mixins.breakpoint(variables2.$small_desktop) {
  .box-panel {
    padding: 25px 50px;
    &.no-margins {
      padding: 0;
      padding: 0;
    }
  }
}