@use "utils/mixins";
@use "utils/variables" as variables2;
@use "variables";

.search-container {
  @include mixins.breakpoint() {
    display: flex;
    flex-direction: column;
    flex: none;
    margin: 25px 0 auto;
    color: variables.$text_muted;
    margin-bottom: 40px;
  }

  input[type=text].search {
    @include mixins.breakpoint() {
      flex: none;
      width: 300px;
      border: 1px solid variables2.$medium_gray;
      border-radius: 5px 0 0 5px;
      font-size: 20px;
      padding-left: 5px;
    }
  }

  .titles-container {
    margin: 10px 0 0 -16px;
  }

  .buttons-container {
    margin: 10px 0 0 -13px;
  }

  .radio-item,
  .checkbox-item {
    display: inline-block;
    position: relative;
  }

  .radio-item input[type=radio],
  .checkbox-item input[type=checkbox] {
    display: none;
  }

  .radio-item label:before,
  .checkbox-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    border: 2px solid variables2.$medium_gray;
    background-color: transparent;
    cursor: pointer;
  }

  .radio-item label:before {
    top: 2px;
    left: 2px;
    margin: 0 5px 0 0;
    border-radius: 11px;
  }

  .checkbox-item label:before {
    top: 2px;
    left: 5px;
    margin: 0 8px 0 0;
  }

  .radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 6px;
    left: 6px;
    content: " ";
    display: block;
    background: black;
  }

  .radio-item:first-child,
  .checkbox-item:first-child {
    margin-right: 10px;
  }

  .radio-item label,
  .checkbox-item label {
    cursor: pointer;
  }

  .checkbox-item input[type=checkbox]:checked + label:after {
    content: '\2714';
    position: absolute;
    top: 5px;
    left: 8px;
    font-size: 10px;
    color: black;
    cursor: pointer;
  }

  .big-margin-left {
    margin-left: 26px;
  }

  .label-in-row {
    margin-right: 25px;
    padding-top: 1px;
  }

  .middle-align {
    align-items: center;
  }
}

.infinite-scroll {
  left: -180px;
  position: relative;

  width: 710px;

  padding: 10px;

  // -ms-overflow-style: none;
}

// .infinite-scroll::-webkit-scrollbar {
//   display: none;
// }