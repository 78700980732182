.dark_blue {
  color: rgb(1, 55, 100);
}

.light_blue {
  color: rgb(1, 136, 206);
}

.light_grey {
  color: rgb(102, 102, 102);
}

.light_red {
  color: rgb(255, 50, 50)
}