.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100%;
  min-width: auto;
}

.minw-auto {
  min-width: auto;
}

.no_margin {
  margin: 0 !important;
}

@media(max-width: 450px) {
  .label-fix {
    margin-bottom: 21px !important;
  }
}

@media(min-width: 510px) {
  .container-set-width {
    width: 450px !important;
  }
}

.state-zip {
  flex-direction: row !important;
}

.address_page, .guardian_page, .golfer_page {
  .p-0 {
    padding: 0 !important;
  }

  .city-col .create_input {
    margin-right: 10px;
  }

  @media(max-width: 768px) {
    .row {
      flex-direction: column;
    }

    .row .is-1-of-2 {
      width: 100% !important;
    }

    .row .is-1-of-2 input[type='text'], .row .is-1-of-2 select {
      width: 100%;
    }

    .row .radio_box {
      width: 50%;
    }

    .city-col .create_input {
      margin-right: 0 !important;
    }
  }
}