@use "utils/variables" as variables2;
@use "variables";

.club-container {
  margin-top: 10px;
  border: 1px solid variables2.$medium_gray;
  border-radius: 5px;
  min-height: 70px;
  min-width: 750px;
  max-width: 750px;

  
  @media only screen and (max-width: variables2.$small_tablet) {
    width: 100%;
    min-width: calc(100vw - 20px);
    flex-direction: column;
  }

  .btn-container {

    & {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;
      border-left: 1px solid variables2.$medium_gray;
    }
    
    @media only screen and (max-width: variables2.$small_tablet) {
      width: 100%;
      border-left: 0px;
      border-top: 1px solid variables2.$medium_gray;
      padding: 10px;
    }

    .join-btn {
      width: 60%;
      padding: 0.7em 2em;

      font-size: 13px;
      font-weight: variables2.$bold;

      border-radius: 20px;
      border: none;

      background-color: black;
      color: white;

      cursor: pointer;
    }
  }

  .info-container {
    width: 80%;
    padding: 15px 20px 12px 28px;
    
    @media only screen and (max-width: variables2.$small_tablet) {
      width: 100%;
    }

    div.row {
      margin-bottom: 5px;
    }

    div p:first-child {
      align-items: flex-start;
    }

    div p:last-child,
    div a:last-child {
      align-items: flex-end;
    }

    .title-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      text-align: left;

      .club-title {
        color: variables.$text_muted;
        font-size: 18px;
        font-weight: variables2.$bold;
      }
    }

    .more-info {
      display: flex;
      justify-content: flex-end;
      width: 25%;
      padding: 0;
      margin-left: 10px;
      margin: auto 10px;

      cursor: pointer;
      border: none;

      color: variables.$legend_light_blue;
      background-color: transparent;

      font-size: 14px;
      font-weight: variables2.$bold;

      .expand-icon {
        font-size: 18px;
      }
    }

    .address {
      color: variables.$text_muted;
      font-size: 14px;
      font-weight: variables2.$medium;

      padding-left: 0;
    }

    .fee-container {
      display: flex;
      justify-content: flex-end;

      .fee-text {
        color: variables.$text_muted;
        font-size: 14px;
        font-weight: variables2.$regular;

        margin-right: 5px;
      }

      .fee-value {
        color: variables.$text_muted;
        font-size: 14px;
        font-weight: variables2.$bold;
      }
    }

    .link:link,
    .link:visited {
      display: flex;
      flex-direction: row;

      color: variables.$legend_light_blue;
      font-size: 14px;
      font-weight: variables2.$bold;
      text-decoration: none;
      padding: 0;
    }

    .link-icon {
      font-size: 14px;
    }

    .info-message {
      margin: 25px auto 10px 0;
      text-align: left;
      font-size: 14px;
      color: variables.$text_muted;
      line-height: 1.25em;
    }
  }
  .fees-table {
    td, th {
      border: 1px solid #c9c9c9;
      padding: 10px;
    }

    th {
      text-align: left;
      width: 50%;

      &.name {
        text-align: right;
      }
    }

    td {
      text-align: center;
    }

    width: 100%;
    border-collapse: collapse;
    color: variables.$text_muted;
  }
}
