@use "sass:math";
@use "mixins";

.row {

  @include mixins.breakpoint() {

    display: flex;
    flex-direction: row;

    margin: 0 -8px 16px -8px;

  }

  &.with-no-bottom-margin {
    margin-bottom: 0;
  }

  &.with-padding {
    padding: 0 8px;
  }

  &.with-padding-vertical {
    padding: 16px 0;
  }

  &.with-padding-both {
    padding: 16px 8px;
  }

  &.align-right {
    justify-content: flex-end;
    align-items: flex-end;
  }

  &.height-28-width-160 {
    height: 28px;
    width: 160px;
  }

  &.jc-sa {
    justify-content: space-around;
  }

  &.centered-items {
    @include mixins.breakpoint() {
      justify-content: center;
    }
  }

  &.margin-top-16 {
    @include mixins.breakpoint() {
      margin-top: 16px;
    }
  }

  &.margin-bottom-32 {
    @include mixins.breakpoint() {
      margin-bottom: 32px;
    }
  }

  &.fr {
    @include mixins.breakpoint() {
      flex-wrap: wrap;
    }
  }

  &.top-m {

    @include mixins.breakpoint() {
      margin-top: 16px !important;
    }

  }

  &:last-child {

    @include mixins.breakpoint() {

      margin-bottom: 0;

    }

  }

  &.relative {
    position: relative;
  }

  .col {

    @include mixins.breakpoint() {

      padding: 0 8px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      flex: 1 1 0;

    }

    &.auto-size {
      @include mixins.breakpoint() {
        flex-basis: auto;
      }
    }

    &.text-right {
      @include mixins.breakpoint() {
        text-align: right;
        justify-content: center;
      }
    }

    &.fx-wd {
      @include mixins.breakpoint() {
        flex: unset;
        width: 25%;
      }
    }

    &.jc-fs {
      @include mixins.breakpoint() {
        justify-content: flex-start;
      }
    }

    &.jc-fe {
      @include mixins.breakpoint() {
        justify-content: flex-end;
      }
    }

    &.jc-c {
      @include mixins.breakpoint() {
        justify-content: center;
      }
    }

    &.ac-c {
      @include mixins.breakpoint() {
        align-content: center;
      }
    }

    &.ai-c {
      @include mixins.breakpoint() {
        align-items: center;
      }
    }

    &.ai-fe {
      align-items: flex-end;
    }

    &.centered {
      @include mixins.breakpoint() {
        margin-right: auto;
        margin-left: auto;
      }
    }

    &.auto {
      @include mixins.breakpoint() {
        flex: 0 0 0;
      }
    }

    &.white-space {
      @include mixins.breakpoint() {
        white-space: nowrap;
      }
    }

    &.is-full {

      @include mixins.breakpoint() {

        flex: none;
        width: 100%;

      }

    }

    &.push-right {

      @include mixins.breakpoint() {
        margin-left: auto;
      }

    }

    &.push-left {

      @include mixins.breakpoint() {
        margin-right: auto;
      }

    }

    @for $i from 1 through 16 {

      @for $j from 1 through 16 {

        @if ($i > $j) or ($i ==$j) or ((math.round(calc($j/$i))==calc($j/$i) ) and not ($j > $i )) {
          //  DO NOTHING
        }

        @else {

          &.is-#{$i}-of-#{$j} {

            @include mixins.breakpoint() {
              flex: none;
              width: math.percentage(calc($i/$j));
            }

          }

        }

      }

    }


  }

}

.columns {

  @include mixins.breakpoint() {

    display: flex;
    flex-direction: column;

  }

}
