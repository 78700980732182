@use "../utils/mixins";
@use "../utils/variables" as variables2;
@use "../variables";

.page_container {
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding: 20px 0 50px 0;
  &.contact {
    max-width: 830px;
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    padding: 20px;
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    padding: 30px 0;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  // header styles
  h1 {
    color: variables2.$dark_midnight_blue;
    font-size: 24px;
    font-weight: variables2.$light;
    line-height: 1.2em;
    margin-bottom: 18px;
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    h1 {
      font-size: 30px;
    }
  }
  @include mixins.breakpoint(variables2.$small_desktop) {
    h1 {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 25px;
    line-height: 1em;
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    h3 {
      font-size: 28px;
    }
  }

  // text styles
  p,
  select,
  option,
  textarea,
  label {
    font-size: 16px;
    border-color: variables.$border_color;
  }
  input[type="text"].large,
  input[type="password"].large,
  input[type="email"].large {
    font-size: 16px;
    border-color: variables.$border_color;
    &:focus {
      border-color: variables.$border_color;
    }
    &.error {
      border-color: variables.$legend_light_red;
    }
  }
  @include mixins.breakpoint(variables2.$small_desktop) {
    p,
    select,
    option,
    textarea,
    label {
      font-size: 18px;
    }
  }

  p {
    margin: 1em 0;
    line-height: 1.5em;
    font-family: "National", sans-serif;
  }
  .top-note {
    margin-top: 0;
    color: variables.$text_muted;
    font-size: 90%;
    font-style: italic;
    font-weight: variables2.$regular;
    position: relative;
    top: 10px;
  }
  .help-text {
    margin: 0;
    font-size: 88%;
    color: variables.$text_muted;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: right;
    span {
      display: inline-block;
      min-width: 40px;
    }
  }
  .text-muted {
    color: variables.$text_muted;
  }

  // page content
  .box-content {
    padding: 0 20px;
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    .box-content {
      padding: 0;
    }
  }

  .box-panel {
    background-color: variables2.$white;
    padding: 20px;
    &.center {
      justify-content: center;
      display: flex;
    }
    &.no-margins {
      padding: 0;
      padding: 0;
    }
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    .box-panel {
      padding: 30px 30px;
      &.no-margins {
        padding: 0;
        padding: 0;
      }
    }
  }
  @include mixins.breakpoint(variables2.$small_desktop) {
    .box-panel {
      padding: 25px 50px;
      &.no-margins {
        padding: 0;
        padding: 0;
      }
    }
  }

  // video full frame
  .video_wrapper {
    position: relative;
    padding: 28% 0 28% 0;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // form layout
  .form-row {
    display: flex;
    flex-direction: column;
    padding: 0;
    .form-group {
      flex: 1 0 auto;
      padding: 12px 0;
      position: relative;
      span.error {
        position: absolute;
        bottom: -17px;
        left: 0;
        color: variables.$legend_light_red;
        font-style: italic;
        font-size: 14px;
      }
    }
  }
  @include mixins.breakpoint(variables2.$small_tablet) {
    .form-row {
      flex-direction: row;
      padding: 12px 0;
      .form-group {
        flex: 1 0 0;
        padding: 0 5px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .buttons-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    max-width: 380px;
    margin: 0 auto;
    .lnk {
      text-decoration: none;
      padding-left: 0;
    }
    .cardinal {
      flex-grow: 1;
    }
  }

  label {
    color: inherit;
    font-weight: variables2.$regular;
  }
  textarea {
    border: 1px solid variables.$border_color;
    width: 100%;
    padding: 20px;
  }

  // GHIN Associations
  .box-content.fix-right-links {
    padding: 0 50px 0 20px;
    @include mixins.breakpoint(variables2.$small_desktop) {
      padding: 0;
    }
  }

  // ABOUT
  &.about {
    // logo header
    .logo_header {
      display: flex;
      justify-content: space-between;
      padding: 30px 20px;
      .ghin {
        display: flex;
        align-items: flex-end;
        background-position: right bottom;
        background-repeat: no-repeat;
        height: 40px;
      }
      .ghin {
        font-size: 24px;
        color: variables2.$dark_midnight_blue;
        font-weight: variables2.$light;
      }
    }
    @include mixins.breakpoint(variables2.$small_tablet) {
      .logo_header {
        padding: 30px 0;
        .ghin {
          background-size: auto 40px;
          padding-right: 125px;
          font-size: 28px;
        }
      }
    }
    @include mixins.breakpoint(variables2.$small_desktop) {
      .logo_header {
        .ghin {
          background-size: auto 40px;
          padding-right: 130px;
          font-size: 34px;
        }
      }
    }

    // section links
    .section_links {
      padding: 15px;
      background: variables2.$white;
      border-bottom: 1px solid variables.$border_color;
      .tabs.light {
        margin: 0;
      }
    }

    h3 {
      color: variables2.$dark_midnight_blue;
      margin-bottom: 20px;
    }
    p {
      line-height: 2em;
    }
    .bigstart::first-letter {
      font-size: 200%;
    }

    @include mixins.breakpoint(variables2.$small_tablet) {
      .pos-rlt {
        .socials {
          right: 20px;
          bottom: 20px;
          a,
          button {
            &.share {
              display: none;
            }
            &.twitter,
            &.facebook,
            &.linkedin {
              display: block;
            }
          }
        }
      }
    }
    @include mixins.breakpoint(variables2.$small_desktop) {
      .pos-rlt {
        .socials {
          a,
          button {
            width: 40px;
            height: 40px;
            background-size: auto 17px;
          }
        }
      }
    }
  }

  // Create Profile
  &.create-profile-page {
    h4 {
      color: #4c7b93;
      margin-bottom: 20px;
      margin-top: -10px;
    }
  }
}
