.discount-box {
  margin-left: 10px;
  background-color: #ffffff;
  border: 2px solid #28a745;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.success-icon {
  color: #28a745;
}

.remove-button {
  background-color: transparent;
  color: #6c757d;
  text-decoration: underline;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.discount-box-hidden {
  justify-content: initial;
  .discount-details {
    margin: auto;
    transform: translateX(-29.6665px);
  }
}
