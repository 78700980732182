@use "utils/mixins";
@use "utils/variables" as variables2;

.first-page-container {
  @include mixins.breakpoint {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }

  .header-text {
    @include mixins.breakpoint {
      width: 100%;
      line-height: 25px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .filter-container {
    
    @media only screen and (max-width: variables2.$small_tablet) {
      width: 100%;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
    }

    .left-right-margin {
      margin-left: 20px;
      margin-right: 20px;
    }
    
    & {
      border: 1px solid variables2.$medium_gray;
      border-radius: 5px;
      padding: 0 25px 30px;
      margin-bottom: 0px !important;
    }
  }

  .translate-y {
    @media only screen and (max-width: variables2.$small_tablet) {
      transform: none;
      margin-top: 10px;
    }
    
    & {
      transform: translateY(14px);
      margin: auto;
    }
  }
  
  .text-under {
    @media only screen and (min-width: variables2.$small_tablet) {
      position: relative;
      display: block;
      p {
        line-height: 0;
        padding-left: 10px;
        margin-top: 10px;
        position: absolute;
        width: 400px;
      }
    }

    @media only screen and (max-width: variables2.$small_tablet) {
      width: 100%;
      p {
        position: relative;
        padding-left: 10px;
        line-height: 18px;
        margin-top: 0;
      }
    }
  }

  .infinite-scroll-component__outerdiv{
    width: 100%;
    padding: 10px;
  }

  .infinite {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 20px;
  }
}
