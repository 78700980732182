@use "utils/mixins";
@use "utils/variables";

.modal {

  .modal__overlay {

    @include mixins.breakpoint() {

      position: fixed;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 99;

      background-color: rgba(variables.$black, 0.5);

      @include mixins.animation(fade_in 600ms both);

      overflow-y: auto;

      display: flex;
      flex-direction: row;

    }

  }

  .modal__content {

    @include mixins.breakpoint() {

      width: auto;

      -webkit-overflow-scrolling: touch;

      outline: none;

      position: relative;

      pointer-events: none;

      margin: auto;

      //  MAGIC TOP / BOTTOM SPACING
      border-top: solid 64px transparent;
      border-bottom: solid 64px transparent;

    }

    .modal__container {

      @include mixins.breakpoint() {

        pointer-events: initial;

        @include mixins.box-shadow(0 5px 25px 0 rgba(variables.$black, 0.5));

      }

      &.hidden {
        display: none;
      }

      &.responsive {
        @include mixins.breakpoint() {
          width: 70vw;
          max-width: 700px;
        }
      }

      .modal__head {

        @include mixins.breakpoint() {

          height: 64px;

          padding: 0 16px;

          display: flex;
          flex-direction: row;
          align-items: center;

          background-color: variables.$bg_blue;

          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          &.auto__height {
            height: auto !important;
          }

        }

        &.alert {
          background-color: #CC0000;

          .modal__icon {
            background: url("../img/alert-icon.svg") no-repeat center;
            width: 30px;
            height: 30px;
          }
        }



        .modal__title {

          @include mixins.breakpoint() {

            color: variables.$white;

            font-size: 24px;
            line-height: 32px;
            font-weight: variables.$medium;

            margin: 0;

          }

          &.long {
            padding: 16px 0px;
          }

        }

        .modal__icon {

          @include mixins.breakpoint() {

            margin: 0 16px 0 0;

          }

          .material-icons-outlined {

            @include mixins.breakpoint() {

              color: variables.$white;

              font-size: 32px;
              line-height: 32px;

            }

          }

        }

        .modal__close {

          @include mixins.breakpoint() {

            width: 32px;
            height: 32px;

            margin: 0 0 0 auto;

            color: variables.$white;
            background-color: transparent;
            border: none;

            cursor: pointer;


            display: flex;
            flex-direction: row;
            justify-content: center;

          }

          &:hover {

            @include mixins.breakpoint() {

              color: variables.$bg_blue;
              background-color: variables.$white;

            }

          }

          .material-icons-outlined {

            @include mixins.breakpoint() {

              font-size: 24px;
              line-height: 32px;

            }

          }

        }

        //  red
        &.red {

          @include mixins.breakpoint() {
            background-color: variables.$cardinal;
          }

          .modal__close {

            &:hover {

              @include mixins.breakpoint() {
                color: variables.$cardinal;
                background-color: variables.$white;
              }

            }

          }

        }

      }

      .modal__body {

        @include mixins.breakpoint() {

          padding: 48px;

          background-color: variables.$white;

        }

        span.label {
          white-space: inherit !important;
        }

        &.alert {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .row {

          &:last-child {

            @include mixins.breakpoint() {
              margin-top: 48px;
            }

            &:only-of-type {
              @include mixins.breakpoint() {
                margin-top: 0;
              }
            }

          }

        }

        .bottom-margin {
          margin-bottom: 20px;
        }

      }

      &.confirmation {
        span {
          color: variables.$amaranth_red;
        }
      }

      &.success {

        .modal__head {

          background-color: #fff;

          .modal__close {
            color: variables.$new_gray;

            :hover {
              color: variables.$bg_blue;
            }

          }
        }

        .success-icon {

          position: absolute;
          left: 50%;
          margin-left: -35px;
          width: 70px;
          height: 70px;
          display: flex;
          border-radius: 50%;
          background-color: #0089ce;
          align-items: center;
          justify-content: center;
          top: -30px;

          i {
            font-size: 60px;
            color: #fff;
          }

          i.warning {
            font-size: 50px;
          }
        }

        .modal__body {

          padding-top: 0;
          text-align: center;

          .modal__title {
            font-size: 48px;
            color: variables.$new_gray;
          }

          .row {
            flex-direction: column;
            width: 60%;
            margin: auto;
            margin-top: 30px !important;
          }

          .actions {
            display: flex;
            flex-direction: column;
            width: 60%;
            margin: auto;
            margin-top: 30px !important;

            .action {
              margin-bottom: 8px;
            }
          }
        }


      }

      //  TYPE B
      &.type-b {

        @include mixins.breakpoint() {}

        .modal__body {
          @include mixins.breakpoint() {

            position: relative;

            padding-top: 80px;

          }
        }

        p {

          &.medium {
            @include mixins.breakpoint() {
              font-size: 18px;
            }
          }

        }

        .modal__close {

          @include mixins.breakpoint() {

            background-color: transparent;
            border: none;

            width: 32px;
            height: 32px;

            position: absolute;

            top: 16px;
            right: 16px;

            cursor: pointer;

          }

        }

        .modal__title {
          @include mixins.breakpoint() {
            font-size: 32px;
            text-align: center;
          }

          &.black {
            color: variables.$black;
          }
        }

        .modal-head__icon {

          .material-icons-outlined {

            @include mixins.breakpoint() {

              width: 128px;
              height: 128px;

              display: block;

              font-size: 128px;
              line-height: 128px;

              border-radius: 50%;

              overflow: hidden;

              position: absolute;

              top: 0;
              left: 50%;

              @include mixins.transform(translate3d(-50%, -50%, 0));

              color: variables.$bg_blue;
              background-color: variables.$white;

            }

          }

        }

      }

    }

  }

}



.custom__modal {
  .modal__overlay {

    @include mixins.breakpoint() {

      position: fixed;

      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      z-index: 99;

      background-color: rgba(variables.$black, 0.5);

      @include mixins.animation(fade_in 600ms both);

      overflow-y: auto;

      display: flex;
      flex-direction: row;

    }

  }

  .modal__content {
    @include mixins.breakpoint() {

      width: auto;

      -webkit-overflow-scrolling: touch;

      outline: none;

      position: relative;

      pointer-events: none;

      margin: auto;

      //  MAGIC TOP / BOTTOM SPACING
      border-top: solid 64px transparent;
      border-bottom: solid 64px transparent;

    }

    .modal__container {

      @include mixins.breakpoint() {

        pointer-events: initial;

        @include mixins.box-shadow(0 5px 25px 0 rgba(variables.$black, 0.5));

      }

      .modal__head {

        &.red {
          background-color: #CC0000;
        }

        &.orange {
          background-color: #FF9900;
        }

        @include mixins.breakpoint() {

          height: 64px;

          padding: 0 16px;

          display: flex;
          flex-direction: row;
          align-items: center;

          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          .modal__icon {
            margin: 0 16px 0 0;
            background: url("../img/alert-icon.svg") no-repeat center;
            width: 30px;
            height: 30px;
          }

          .modal__title {

            @include mixins.breakpoint() {

              color: variables.$white;

              font-size: 24px;
              line-height: 32px;
              font-weight: variables.$medium;

              margin: 0;

            }

            &.long {
              padding: 16px 0px;
            }

          }

        }
      }

      .modal__body {
        padding: 48px;
        background-color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        p {
          color: #333333;
          text-align: justify;
          font-size: 16px;
          line-height: 1.2;
        }

      }
    }
  }

}