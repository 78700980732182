@use "../utils/variables" as variables2;

.container {
  max-width: 470px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .center {
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .left {
    align-self: flex-start;
  }

  .row-display {
    display: flex;
    flex-direction: column;

    span {
      padding-bottom: 2px;
    }
  }

  .col-display {
    display: flex;
    flex-direction: row;
  }

  .bottom-actions {
    display: flex;
    flex-direction: row;
    margin-top: 2.5em;
    margin-bottom: 2em;
    justify-content: center;
  }

  .note {
    font-size: 16px;
    line-height: 1.2em;
    font-family: "National", sans-serif;
    max-width: 450px;
    text-align: center;
    a {
      text-decoration: underline;
    }

    &.gray {
      color: #6b6b6b;
      a {
        color: #6b6b6b;
      }
    }
  }

  .success-icon {
    height: 50px;
    background: url("../assets/img/icons/check_icon.svg") no-repeat center;
    margin-top: 2em;
  }

  .success-title {
    color: variables2.$dark_midnight_blue;
    text-align: center;
    font-size: 1.4em;
    margin-top: 1.5em;
    margin-bottom: 2.5em;
  }

  .row {
    .col-sm {
      &.two {
        margin-left: 34%;

        @media screen and (max-width: 768px) and (min-width: 481px){
          margin-left: 32%;
        }

        @media screen and (max-width: 480px) {
          margin-left: 28%
        }
      }
      &.third {
        margin-left: 4%;
        margin-top: 4%;
        height: 5px;
        width: 500px;

        @media screen and (max-width: 768px) and (min-width: 481px) {
          width: 400px;
        }

        @media screen and (max-width: 480px) {
          width: 300px;
        }
        p {
          &.text-bold {
            font-style: italic;
            font-weight: bold;
          }
          &.text-info {
            line-height: 18px;
          }
        }
      }
    }
  }
}
