@use "mixins";
@use "variables";

//	FONT DECLARATION
body > *,
input {
	font-family: 'National', sans-serif;
	font-feature-settings: "tnum";
}

//	FONT SMOOTHING
html {
	-webkit-font-smoothing: antialiased;
}

//	BORDERBOX SETUP
* {
	box-sizing: border-box;
	outline: none;
}

//	GENERAL BACKGROUND
body {
  // background-color: $gray_bg;
}

//	FIXED HEIGHT FOR ROOT
#root {

	min-height: 100vh;

	display: flex;
	flex-direction: column;

}

//	PREVENT SCROLL
html {

	&.prevent-scroll {
		@include mixins.breakpoint() {
			overflow: hidden;
		}
	}

}

//	BODY MODAL IS OPEN
body {

	&.modal--is-open {

	}

}

main {
	@include mixins.breakpoint() {
		padding: 16px 0;
	}
}

.separator {

	@include mixins.breakpoint() {

		width: 100%;
		height: 0;

		margin: 16px 0;

	}

	&.dark {
		@include mixins.breakpoint() {
			border: solid 1px variables.$medium_gray;
		}
	}

	&.light {
		@include mixins.breakpoint() {
			border: solid 1px variables.$light_gray;
		}
	}

}

span {
	&.gray {
		color: variables.$dark_gray!important;
		font-weight:  variables.$regular;
	}
	&.bold {
		font-weight: variables.$bold;
	}
	&.italic {
		font-style: italic;
	}
	&.underline {
		text-decoration: underline;
	}
	&.red {
		color: variables.$amaranth_red;
	}
}

// Editor text (nu stiam unde sa fac overwrite pe clasa lui default...

.rdw-editor-main {
	height: 25vh;
	border: 1px solid #f1f1f1;
	overflow: auto;
	padding: 0px 10px;
	box-sizing: border-box;
}
