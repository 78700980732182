@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "National Book";
  src: url("/src/styles/fonts/National-Book.eot");
  src: url("/src/styles/fonts/National-Book.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Book.woff2") format("woff2"), url("/src/styles/fonts/National-Book.woff") format("woff"), url("/src/styles/fonts/National-Book.ttf") format("truetype"), url("/src/styles/fonts/National-Book.svg#National-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Light.eot");
  src: url("/src/styles/fonts/National-Light.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Light.woff2") format("woff2"), url("/src/styles/fonts/National-Light.woff") format("woff"), url("/src/styles/fonts/National-Light.ttf") format("truetype"), url("/src/styles/fonts/National-Light.svg#National-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "National Regular";
  src: url("/src/styles/fonts/National-RegularItalic.eot");
  src: url("/src/styles/fonts/National-RegularItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-RegularItalic.woff2") format("woff2"), url("/src/styles/fonts/National-RegularItalic.woff") format("woff"), url("/src/styles/fonts/National-RegularItalic.ttf") format("truetype"), url("/src/styles/fonts/National-RegularItalic.svg#National-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "National Book";
  src: url("/src/styles/fonts/National-BookItalic.eot");
  src: url("/src/styles/fonts/National-BookItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-BookItalic.woff2") format("woff2"), url("/src/styles/fonts/National-BookItalic.woff") format("woff"), url("/src/styles/fonts/National-BookItalic.ttf") format("truetype"), url("/src/styles/fonts/National-BookItalic.svg#National-BookItalic") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-BoldItalic.eot");
  src: url("/src/styles/fonts/National-BoldItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-BoldItalic.woff2") format("woff2"), url("/src/styles/fonts/National-BoldItalic.woff") format("woff"), url("/src/styles/fonts/National-BoldItalic.ttf") format("truetype"), url("/src/styles/fonts/National-BoldItalic.svg#National-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-SemiboldItalic.eot");
  src: url("/src/styles/fonts/National-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-SemiboldItalic.woff2") format("woff2"), url("/src/styles/fonts/National-SemiboldItalic.woff") format("woff"), url("/src/styles/fonts/National-SemiboldItalic.ttf") format("truetype"), url("/src/styles/fonts/National-SemiboldItalic.svg#National-SemiboldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-ThinItalic.eot");
  src: url("/src/styles/fonts/National-ThinItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-ThinItalic.woff2") format("woff2"), url("/src/styles/fonts/National-ThinItalic.woff") format("woff"), url("/src/styles/fonts/National-ThinItalic.ttf") format("truetype"), url("/src/styles/fonts/National-ThinItalic.svg#National-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Black.eot");
  src: url("/src/styles/fonts/National-Black.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Black.woff2") format("woff2"), url("/src/styles/fonts/National-Black.woff") format("woff"), url("/src/styles/fonts/National-Black.ttf") format("truetype"), url("/src/styles/fonts/National-Black.svg#National-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-BlackItalic.eot");
  src: url("/src/styles/fonts/National-BlackItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-BlackItalic.woff2") format("woff2"), url("/src/styles/fonts/National-BlackItalic.woff") format("woff"), url("/src/styles/fonts/National-BlackItalic.ttf") format("truetype"), url("/src/styles/fonts/National-BlackItalic.svg#National-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Bold.eot");
  src: url("/src/styles/fonts/National-Bold.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Bold.woff2") format("woff2"), url("/src/styles/fonts/National-Bold.woff") format("woff"), url("/src/styles/fonts/National-Bold.ttf") format("truetype"), url("/src/styles/fonts/National-Bold.svg#National-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-MediumItalic.eot");
  src: url("/src/styles/fonts/National-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-MediumItalic.woff2") format("woff2"), url("/src/styles/fonts/National-MediumItalic.woff") format("woff"), url("/src/styles/fonts/National-MediumItalic.ttf") format("truetype"), url("/src/styles/fonts/National-MediumItalic.svg#National-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Regular.eot");
  src: url("/src/styles/fonts/National-Regular.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Regular.woff2") format("woff2"), url("/src/styles/fonts/National-Regular.woff") format("woff"), url("/src/styles/fonts/National-Regular.ttf") format("truetype"), url("/src/styles/fonts/National-Regular.svg#National-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-LightItalic.eot");
  src: url("/src/styles/fonts/National-LightItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-LightItalic.woff2") format("woff2"), url("/src/styles/fonts/National-LightItalic.woff") format("woff"), url("/src/styles/fonts/National-LightItalic.ttf") format("truetype"), url("/src/styles/fonts/National-LightItalic.svg#National-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Medium.eot");
  src: url("/src/styles/fonts/National-Medium.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Medium.woff2") format("woff2"), url("/src/styles/fonts/National-Medium.woff") format("woff"), url("/src/styles/fonts/National-Medium.ttf") format("truetype"), url("/src/styles/fonts/National-Medium.svg#National-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Semibold.eot");
  src: url("/src/styles/fonts/National-Semibold.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Semibold.woff2") format("woff2"), url("/src/styles/fonts/National-Semibold.woff") format("woff"), url("/src/styles/fonts/National-Semibold.ttf") format("truetype"), url("/src/styles/fonts/National-Semibold.svg#National-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-ExtraboldItalic.eot");
  src: url("/src/styles/fonts/National-ExtraboldItalic.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-ExtraboldItalic.woff2") format("woff2"), url("/src/styles/fonts/National-ExtraboldItalic.woff") format("woff"), url("/src/styles/fonts/National-ExtraboldItalic.ttf") format("truetype"), url("/src/styles/fonts/National-ExtraboldItalic.svg#National-ExtraboldItalic") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Thin.eot");
  src: url("/src/styles/fonts/National-Thin.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Thin.woff2") format("woff2"), url("/src/styles/fonts/National-Thin.woff") format("woff"), url("/src/styles/fonts/National-Thin.ttf") format("truetype"), url("/src/styles/fonts/National-Thin.svg#National-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "National";
  src: url("/src/styles/fonts/National-Extrabold.eot");
  src: url("/src/styles/fonts/National-Extrabold.eot?#iefix") format("embedded-opentype"), url("/src/styles/fonts/National-Extrabold.woff2") format("woff2"), url("/src/styles/fonts/National-Extrabold.woff") format("woff"), url("/src/styles/fonts/National-Extrabold.ttf") format("truetype"), url("/src/styles/fonts/National-Extrabold.svg#National-Extrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}
@media screen {
  h2 {
    color: #00365f;
    font-size: 22px;
    font-weight: 500;
    margin: 0 0 32px 0;
  }
}

body > *,
input {
  font-family: "National", sans-serif;
  font-feature-settings: "tnum";
}

html {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  outline: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen {
  html.prevent-scroll {
    overflow: hidden;
  }
}

@media screen {
  main {
    padding: 16px 0;
  }
}

@media screen {
  .separator {
    width: 100%;
    height: 0;
    margin: 16px 0;
  }
}
@media screen {
  .separator.dark {
    border: solid 1px #cccccc;
  }
}
@media screen {
  .separator.light {
    border: solid 1px #efefef;
  }
}

span.gray {
  color: #999 !important;
  font-weight: 400;
}
span.bold {
  font-weight: 700;
}
span.italic {
  font-style: italic;
}
span.underline {
  text-decoration: underline;
}
span.red {
  color: #da1a31;
}

.rdw-editor-main {
  height: 25vh;
  border: 1px solid #f1f1f1;
  overflow: auto;
  padding: 0px 10px;
  box-sizing: border-box;
}

@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-o-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-moz-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-o-keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fade_in_up {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    -moz-transform: translate3d(0, 50%, 0);
    -ms-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@-moz-keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@-o-keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@keyframes error {
  0% {
    color: #fff;
  }
  100% {
    color: #C9243F;
  }
}
@-webkit-keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@-moz-keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@-o-keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@keyframes errorDisabled {
  0% {
    color: #fff;
    background-color: #C9243F;
  }
  100% {
    color: #C9243F;
    background-color: gba(239, 239, 239, 0.5);
  }
}
@media screen {
  .row {
    display: flex;
    flex-direction: row;
    margin: 0 -8px 16px -8px;
  }
}
.row.with-no-bottom-margin {
  margin-bottom: 0;
}
.row.with-padding {
  padding: 0 8px;
}
.row.with-padding-vertical {
  padding: 16px 0;
}
.row.with-padding-both {
  padding: 16px 8px;
}
.row.align-right {
  justify-content: flex-end;
  align-items: flex-end;
}
.row.height-28-width-160 {
  height: 28px;
  width: 160px;
}
.row.jc-sa {
  justify-content: space-around;
}
@media screen {
  .row.centered-items {
    justify-content: center;
  }
}
@media screen {
  .row.margin-top-16 {
    margin-top: 16px;
  }
}
@media screen {
  .row.margin-bottom-32 {
    margin-bottom: 32px;
  }
}
@media screen {
  .row.fr {
    flex-wrap: wrap;
  }
}
@media screen {
  .row.top-m {
    margin-top: 16px !important;
  }
}
@media screen {
  .row:last-child {
    margin-bottom: 0;
  }
}
.row.relative {
  position: relative;
}
@media screen {
  .row .col {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 0;
  }
}
@media screen {
  .row .col.auto-size {
    flex-basis: auto;
  }
}
@media screen {
  .row .col.text-right {
    text-align: right;
    justify-content: center;
  }
}
@media screen {
  .row .col.fx-wd {
    flex: unset;
    width: 25%;
  }
}
@media screen {
  .row .col.jc-fs {
    justify-content: flex-start;
  }
}
@media screen {
  .row .col.jc-fe {
    justify-content: flex-end;
  }
}
@media screen {
  .row .col.jc-c {
    justify-content: center;
  }
}
@media screen {
  .row .col.ac-c {
    align-content: center;
  }
}
@media screen {
  .row .col.ai-c {
    align-items: center;
  }
}
.row .col.ai-fe {
  align-items: flex-end;
}
@media screen {
  .row .col.centered {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen {
  .row .col.auto {
    flex: 0 0 0;
  }
}
@media screen {
  .row .col.white-space {
    white-space: nowrap;
  }
}
@media screen {
  .row .col.is-full {
    flex: none;
    width: 100%;
  }
}
@media screen {
  .row .col.push-right {
    margin-left: auto;
  }
}
@media screen {
  .row .col.push-left {
    margin-right: auto;
  }
}
@media screen {
  .row .col.is-1-of-2 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-1-of-3 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-1-of-4 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-1-of-5 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-1-of-6 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .row .col.is-1-of-7 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .row .col.is-1-of-8 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .row .col.is-1-of-9 {
    flex: none;
    width: 11.1111111111%;
  }
}
@media screen {
  .row .col.is-1-of-10 {
    flex: none;
    width: 10%;
  }
}
@media screen {
  .row .col.is-1-of-11 {
    flex: none;
    width: 9.0909090909%;
  }
}
@media screen {
  .row .col.is-1-of-12 {
    flex: none;
    width: 8.3333333333%;
  }
}
@media screen {
  .row .col.is-1-of-13 {
    flex: none;
    width: 7.6923076923%;
  }
}
@media screen {
  .row .col.is-1-of-14 {
    flex: none;
    width: 7.1428571429%;
  }
}
@media screen {
  .row .col.is-1-of-15 {
    flex: none;
    width: 6.6666666667%;
  }
}
@media screen {
  .row .col.is-1-of-16 {
    flex: none;
    width: 6.25%;
  }
}
@media screen {
  .row .col.is-2-of-3 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-2-of-4 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-2-of-5 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-2-of-6 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-2-of-7 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .row .col.is-2-of-8 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-2-of-9 {
    flex: none;
    width: 22.2222222222%;
  }
}
@media screen {
  .row .col.is-2-of-10 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-2-of-11 {
    flex: none;
    width: 18.1818181818%;
  }
}
@media screen {
  .row .col.is-2-of-12 {
    flex: none;
    width: 16.6666666667%;
  }
}
@media screen {
  .row .col.is-2-of-13 {
    flex: none;
    width: 15.3846153846%;
  }
}
@media screen {
  .row .col.is-2-of-14 {
    flex: none;
    width: 14.2857142857%;
  }
}
@media screen {
  .row .col.is-2-of-15 {
    flex: none;
    width: 13.3333333333%;
  }
}
@media screen {
  .row .col.is-2-of-16 {
    flex: none;
    width: 12.5%;
  }
}
@media screen {
  .row .col.is-3-of-4 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-3-of-5 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-3-of-6 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-3-of-7 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .row .col.is-3-of-8 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .row .col.is-3-of-9 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-3-of-10 {
    flex: none;
    width: 30%;
  }
}
@media screen {
  .row .col.is-3-of-11 {
    flex: none;
    width: 27.2727272727%;
  }
}
@media screen {
  .row .col.is-3-of-12 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-3-of-13 {
    flex: none;
    width: 23.0769230769%;
  }
}
@media screen {
  .row .col.is-3-of-14 {
    flex: none;
    width: 21.4285714286%;
  }
}
@media screen {
  .row .col.is-3-of-15 {
    flex: none;
    width: 20%;
  }
}
@media screen {
  .row .col.is-3-of-16 {
    flex: none;
    width: 18.75%;
  }
}
@media screen {
  .row .col.is-4-of-5 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-4-of-6 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-4-of-7 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .row .col.is-4-of-8 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-4-of-9 {
    flex: none;
    width: 44.4444444444%;
  }
}
@media screen {
  .row .col.is-4-of-10 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-4-of-11 {
    flex: none;
    width: 36.3636363636%;
  }
}
@media screen {
  .row .col.is-4-of-12 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-4-of-13 {
    flex: none;
    width: 30.7692307692%;
  }
}
@media screen {
  .row .col.is-4-of-14 {
    flex: none;
    width: 28.5714285714%;
  }
}
@media screen {
  .row .col.is-4-of-15 {
    flex: none;
    width: 26.6666666667%;
  }
}
@media screen {
  .row .col.is-4-of-16 {
    flex: none;
    width: 25%;
  }
}
@media screen {
  .row .col.is-5-of-6 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .row .col.is-5-of-7 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .row .col.is-5-of-8 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .row .col.is-5-of-9 {
    flex: none;
    width: 55.5555555556%;
  }
}
@media screen {
  .row .col.is-5-of-10 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-5-of-11 {
    flex: none;
    width: 45.4545454545%;
  }
}
@media screen {
  .row .col.is-5-of-12 {
    flex: none;
    width: 41.6666666667%;
  }
}
@media screen {
  .row .col.is-5-of-13 {
    flex: none;
    width: 38.4615384615%;
  }
}
@media screen {
  .row .col.is-5-of-14 {
    flex: none;
    width: 35.7142857143%;
  }
}
@media screen {
  .row .col.is-5-of-15 {
    flex: none;
    width: 33.3333333333%;
  }
}
@media screen {
  .row .col.is-5-of-16 {
    flex: none;
    width: 31.25%;
  }
}
@media screen {
  .row .col.is-6-of-7 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .row .col.is-6-of-8 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-6-of-9 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-6-of-10 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-6-of-11 {
    flex: none;
    width: 54.5454545455%;
  }
}
@media screen {
  .row .col.is-6-of-12 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-6-of-13 {
    flex: none;
    width: 46.1538461538%;
  }
}
@media screen {
  .row .col.is-6-of-14 {
    flex: none;
    width: 42.8571428571%;
  }
}
@media screen {
  .row .col.is-6-of-15 {
    flex: none;
    width: 40%;
  }
}
@media screen {
  .row .col.is-6-of-16 {
    flex: none;
    width: 37.5%;
  }
}
@media screen {
  .row .col.is-7-of-8 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .row .col.is-7-of-9 {
    flex: none;
    width: 77.7777777778%;
  }
}
@media screen {
  .row .col.is-7-of-10 {
    flex: none;
    width: 70%;
  }
}
@media screen {
  .row .col.is-7-of-11 {
    flex: none;
    width: 63.6363636364%;
  }
}
@media screen {
  .row .col.is-7-of-12 {
    flex: none;
    width: 58.3333333333%;
  }
}
@media screen {
  .row .col.is-7-of-13 {
    flex: none;
    width: 53.8461538462%;
  }
}
@media screen {
  .row .col.is-7-of-14 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-7-of-15 {
    flex: none;
    width: 46.6666666667%;
  }
}
@media screen {
  .row .col.is-7-of-16 {
    flex: none;
    width: 43.75%;
  }
}
@media screen {
  .row .col.is-8-of-9 {
    flex: none;
    width: 88.8888888889%;
  }
}
@media screen {
  .row .col.is-8-of-10 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-8-of-11 {
    flex: none;
    width: 72.7272727273%;
  }
}
@media screen {
  .row .col.is-8-of-12 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-8-of-13 {
    flex: none;
    width: 61.5384615385%;
  }
}
@media screen {
  .row .col.is-8-of-14 {
    flex: none;
    width: 57.1428571429%;
  }
}
@media screen {
  .row .col.is-8-of-15 {
    flex: none;
    width: 53.3333333333%;
  }
}
@media screen {
  .row .col.is-8-of-16 {
    flex: none;
    width: 50%;
  }
}
@media screen {
  .row .col.is-9-of-10 {
    flex: none;
    width: 90%;
  }
}
@media screen {
  .row .col.is-9-of-11 {
    flex: none;
    width: 81.8181818182%;
  }
}
@media screen {
  .row .col.is-9-of-12 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-9-of-13 {
    flex: none;
    width: 69.2307692308%;
  }
}
@media screen {
  .row .col.is-9-of-14 {
    flex: none;
    width: 64.2857142857%;
  }
}
@media screen {
  .row .col.is-9-of-15 {
    flex: none;
    width: 60%;
  }
}
@media screen {
  .row .col.is-9-of-16 {
    flex: none;
    width: 56.25%;
  }
}
@media screen {
  .row .col.is-10-of-11 {
    flex: none;
    width: 90.9090909091%;
  }
}
@media screen {
  .row .col.is-10-of-12 {
    flex: none;
    width: 83.3333333333%;
  }
}
@media screen {
  .row .col.is-10-of-13 {
    flex: none;
    width: 76.9230769231%;
  }
}
@media screen {
  .row .col.is-10-of-14 {
    flex: none;
    width: 71.4285714286%;
  }
}
@media screen {
  .row .col.is-10-of-15 {
    flex: none;
    width: 66.6666666667%;
  }
}
@media screen {
  .row .col.is-10-of-16 {
    flex: none;
    width: 62.5%;
  }
}
@media screen {
  .row .col.is-11-of-12 {
    flex: none;
    width: 91.6666666667%;
  }
}
@media screen {
  .row .col.is-11-of-13 {
    flex: none;
    width: 84.6153846154%;
  }
}
@media screen {
  .row .col.is-11-of-14 {
    flex: none;
    width: 78.5714285714%;
  }
}
@media screen {
  .row .col.is-11-of-15 {
    flex: none;
    width: 73.3333333333%;
  }
}
@media screen {
  .row .col.is-11-of-16 {
    flex: none;
    width: 68.75%;
  }
}
@media screen {
  .row .col.is-12-of-13 {
    flex: none;
    width: 92.3076923077%;
  }
}
@media screen {
  .row .col.is-12-of-14 {
    flex: none;
    width: 85.7142857143%;
  }
}
@media screen {
  .row .col.is-12-of-15 {
    flex: none;
    width: 80%;
  }
}
@media screen {
  .row .col.is-12-of-16 {
    flex: none;
    width: 75%;
  }
}
@media screen {
  .row .col.is-13-of-14 {
    flex: none;
    width: 92.8571428571%;
  }
}
@media screen {
  .row .col.is-13-of-15 {
    flex: none;
    width: 86.6666666667%;
  }
}
@media screen {
  .row .col.is-13-of-16 {
    flex: none;
    width: 81.25%;
  }
}
@media screen {
  .row .col.is-14-of-15 {
    flex: none;
    width: 93.3333333333%;
  }
}
@media screen {
  .row .col.is-14-of-16 {
    flex: none;
    width: 87.5%;
  }
}
@media screen {
  .row .col.is-15-of-16 {
    flex: none;
    width: 93.75%;
  }
}

@media screen {
  .columns {
    display: flex;
    flex-direction: column;
  }
}

h2 .is-error {
  color: #C9243F;
}
@media screen {
  h2.page__title {
    color: #000;
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0 0 16px 0;
  }
}

@media screen {
  h4.section_title {
    color: #000;
    font-size: 16px;
    line-height: 40px;
    font-weight: 700;
  }
}

@media screen {
  strong {
    font-weight: 700;
  }
}
strong.medium {
  font-weight: 500;
}

@media screen {
  p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen {
  p:last-child {
    margin: 0;
  }
}
@media screen {
  p.link {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
}
@media screen {
  p.align-center {
    text-align: center;
  }
}
@media screen {
  p.large {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;
  }
}
@media screen {
  p.medium {
    font-size: 24px !important;
    line-height: 24px;
    margin: 0 0 24px;
  }
}
@media screen {
  p i {
    font-style: italic;
  }
}
@media screen {
  p.gray {
    color: #999;
  }
}
@media screen {
  p.pn_blue {
    color: #0089ce;
  }
}
@media screen {
  p.pn_red {
    color: #da1a31;
  }
}
@media screen {
  p.pn_small {
    font-weight: 500;
  }
}
@media screen {
  p.pn_bold {
    font-size: 1.1rem;
  }
}

@media screen {
  a.mailto_lnk {
    color: #000;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    -webkit-transition: all 300ms ease-in-out 0ms;
    -moz-transition: all 300ms ease-in-out 0ms;
    -ms-transition: all 300ms ease-in-out 0ms;
    transition: all 300ms ease-in-out 0ms;
  }
}
@media screen {
  a.mailto_lnk:hover {
    color: #00365f;
  }
}

@media screen {
  .label24 {
    line-height: 24px;
  }
}

@media screen {
  h2.section__title {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }
}
@media screen {
  h2.section__title.large {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 16px;
  }
}

@media screen {
  .modal .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-animation: fade_in 600ms both;
    -moz-animation: fade_in 600ms both;
    -o-animation: fade_in 600ms both;
    animation: fade_in 600ms both;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .modal .modal__content {
    width: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    border-top: solid 64px transparent;
    border-bottom: solid 64px transparent;
  }
}
@media screen {
  .modal .modal__content .modal__container {
    pointer-events: initial;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }
}
.modal .modal__content .modal__container.hidden {
  display: none;
}
@media screen {
  .modal .modal__content .modal__container.responsive {
    width: 70vw;
    max-width: 700px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head {
    height: 64px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #0089ce;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .modal .modal__content .modal__container .modal__head.auto__height {
    height: auto !important;
  }
}
.modal .modal__content .modal__container .modal__head.alert {
  background-color: #CC0000;
}
.modal .modal__content .modal__container .modal__head.alert .modal__icon {
  background: url("../img/alert-icon.svg") no-repeat center;
  width: 30px;
  height: 30px;
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__title {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin: 0;
  }
}
.modal .modal__content .modal__container .modal__head .modal__title.long {
  padding: 16px 0px;
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__icon {
    margin: 0 16px 0 0;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__icon .material-icons-outlined {
    color: #fff;
    font-size: 32px;
    line-height: 32px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__close {
    width: 32px;
    height: 32px;
    margin: 0 0 0 auto;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__close:hover {
    color: #0089ce;
    background-color: #fff;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head .modal__close .material-icons-outlined {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head.red {
    background-color: #C9243F;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__head.red .modal__close:hover {
    color: #C9243F;
    background-color: #fff;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body {
    padding: 48px;
    background-color: #fff;
  }
}
.modal .modal__content .modal__container .modal__body span.label {
  white-space: inherit !important;
}
.modal .modal__content .modal__container .modal__body.alert {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen {
  .modal .modal__content .modal__container .modal__body .row:last-child {
    margin-top: 48px;
  }
}
@media screen {
  .modal .modal__content .modal__container .modal__body .row:last-child:only-of-type {
    margin-top: 0;
  }
}
.modal .modal__content .modal__container .modal__body .bottom-margin {
  margin-bottom: 20px;
}
.modal .modal__content .modal__container.confirmation span {
  color: #da1a31;
}
.modal .modal__content .modal__container.success .modal__head {
  background-color: #fff;
}
.modal .modal__content .modal__container.success .modal__head .modal__close {
  color: #acacac;
}
.modal .modal__content .modal__container.success .modal__head .modal__close :hover {
  color: #0089ce;
}
.modal .modal__content .modal__container.success .success-icon {
  position: absolute;
  left: 50%;
  margin-left: -35px;
  width: 70px;
  height: 70px;
  display: flex;
  border-radius: 50%;
  background-color: #0089ce;
  align-items: center;
  justify-content: center;
  top: -30px;
}
.modal .modal__content .modal__container.success .success-icon i {
  font-size: 60px;
  color: #fff;
}
.modal .modal__content .modal__container.success .success-icon i.warning {
  font-size: 50px;
}
.modal .modal__content .modal__container.success .modal__body {
  padding-top: 0;
  text-align: center;
}
.modal .modal__content .modal__container.success .modal__body .modal__title {
  font-size: 48px;
  color: #acacac;
}
.modal .modal__content .modal__container.success .modal__body .row {
  flex-direction: column;
  width: 60%;
  margin: auto;
  margin-top: 30px !important;
}
.modal .modal__content .modal__container.success .modal__body .actions {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
  margin-top: 30px !important;
}
.modal .modal__content .modal__container.success .modal__body .actions .action {
  margin-bottom: 8px;
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal__body {
    position: relative;
    padding-top: 80px;
  }
}
@media screen {
  .modal .modal__content .modal__container.type-b p.medium {
    font-size: 18px;
  }
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal__close {
    background-color: transparent;
    border: none;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal__title {
    font-size: 32px;
    text-align: center;
  }
}
.modal .modal__content .modal__container.type-b .modal__title.black {
  color: #000;
}
@media screen {
  .modal .modal__content .modal__container.type-b .modal-head__icon .material-icons-outlined {
    width: 128px;
    height: 128px;
    display: block;
    font-size: 128px;
    line-height: 128px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    -moz-transform: translate3d(-50%, -50%, 0);
    -ms-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    color: #0089ce;
    background-color: #fff;
  }
}

@media screen {
  .custom__modal .modal__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-animation: fade_in 600ms both;
    -moz-animation: fade_in 600ms both;
    -o-animation: fade_in 600ms both;
    animation: fade_in 600ms both;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .custom__modal .modal__content {
    width: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    border-top: solid 64px transparent;
    border-bottom: solid 64px transparent;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container {
    pointer-events: initial;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
  }
}
.custom__modal .modal__content .modal__container .modal__head.red {
  background-color: #CC0000;
}
.custom__modal .modal__content .modal__container .modal__head.orange {
  background-color: #FF9900;
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__head {
    height: 64px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .custom__modal .modal__content .modal__container .modal__head .modal__icon {
    margin: 0 16px 0 0;
    background: url("../img/alert-icon.svg") no-repeat center;
    width: 30px;
    height: 30px;
  }
}
@media screen {
  .custom__modal .modal__content .modal__container .modal__head .modal__title {
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin: 0;
  }
  .custom__modal .modal__content .modal__container .modal__head .modal__title.long {
    padding: 16px 0px;
  }
}
.custom__modal .modal__content .modal__container .modal__body {
  padding: 48px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.custom__modal .modal__content .modal__container .modal__body p {
  color: #333333;
  text-align: justify;
  font-size: 16px;
  line-height: 1.2;
}

.dark_blue {
  color: rgb(1, 55, 100);
}

.light_blue {
  color: rgb(1, 136, 206);
}

.light_grey {
  color: rgb(102, 102, 102);
}

.light_red {
  color: rgb(255, 50, 50);
}

.logo-image-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.bold-500 {
  font-weight: 500;
}

.gray-bar {
  height: 15px;
  margin: 0;
  border: none;
  background-color: #e8e8e8;
}

.text-align-center {
  text-align: center;
}

.receipt-div {
  width: 450px;
  align-self: center;
}

.logo-image {
  max-height: 100px;
  margin: auto;
}

.club-name-header {
  margin-top: 25px;
  font-weight: 700;
  font-size: 35px;
}

.grey-bottom-line {
  border-bottom: 2px solid #F6F6F6;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.info-box {
  padding: 0px 20px;
  width: 400px;
}
.info-box p {
  font-size: 15px;
  line-height: 18px;
}
@media only screen and (max-width: 768px) {
  .info-box {
    width: 100%;
    padding: 0px 5px;
  }
}

.info-box-association p {
  font-size: 15px;
  line-height: 18px;
}

.flex-end {
  align-items: flex-end;
}
@media only screen and (max-width: 768px) {
  .flex-end {
    width: 100%;
  }
}

.flex-start {
  align-items: flex-start;
}

.header-sentry-logo {
  display: block;
  height: 50px;
  width: auto;
  margin: 0 0 0 auto;
}
@media screen and (min-width: 768px) {
  .header-sentry-logo {
    margin: 0 0 0 32px;
  }
}

.usga_logo {
  z-index: -5;
}
@media screen {
  .usga_logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #00365f;
    font-size: 13px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    position: relative;
    cursor: pointer;
  }
}
@media screen and (min-width: 1280px) {
  .usga_logo {
    font-size: 16px;
    line-height: 16px;
  }
}
@media screen {
  .usga_logo::before {
    content: "";
    display: block;
    width: 80px;
    height: 24px;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: contain;
    background-image: url("../assets/img/logos/logo_usga.svg");
    margin: 0 8px 0 0;
  }
}
@media screen and (min-width: 1280px) {
  .usga_logo::before {
    width: 90px;
    height: 32px;
  }
}

@media screen {
  .footer__container {
    height: 100%;
    padding: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1280px) {
  .footer__container {
    margin: 0 auto;
  }
}

.grey_background {
  background-color: #F5F5F5;
  padding: 10px 20px 10px 20px;
  border: #F5F5F5 solid 1px;
  border-radius: 5px;
}

.align-items-end {
  align-items: flex-end;
}

.payment_container {
  align-self: center;
  width: 450px;
}
@media only screen and (max-width: 768px) {
  .payment_container {
    width: 370px;
  }
}
.payment_container .MuiFormControlLabel-label {
  margin-top: 4px;
  line-height: 1.3;
}
.payment_container .MuiCheckbox-root {
  padding: 0px;
  margin-top: auto;
  margin-bottom: auto;
}

.width-700-center {
  width: 700px;
  margin-left: calc((100% - 684px) / 2);
  margin-right: calc((100% - 684px) / 2);
}
@media only screen and (max-width: 768px) {
  .width-700-center {
    width: inherit;
    margin-left: auto;
    margin-right: auto;
  }
}

.agree_terms label {
  display: inline;
  padding-left: 10px;
  line-height: 1.3;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.create-ghin-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.payment_inputs {
  font-size: 50;
}

.small_bottom_margin {
  margin-bottom: 3px;
}

.med_bottom_margin {
  margin-bottom: 12px;
}

.medium_bottom_margin {
  margin-bottom: 15px;
}

.row_bottom_margin {
  margin-bottom: 16px;
}

.large_bottom_margin {
  margin-bottom: 30px;
}

.small_top_margin {
  margin-top: 3px;
}

.no_top_margin {
  margin-top: 0 !important;
}

.no_bottom_margin {
  margin-bottom: 0 !important;
}

.no_right_margin {
  margin-right: 0 !important;
}

.no_left_margin {
  margin-left: 0 !important;
}

.bold {
  font-weight: bold;
}

.big-container {
  margin-top: 4vh;
  width: 339px;
  text-align: center;
}

.big-text {
  font-size: 20px !important;
}

.x-large-text {
  font-size: 25px !important;
}

.title {
  font-size: 35px !important;
}

.medium-title {
  font-size: 30px !important;
}

.align-text-center {
  text-align: center;
}

.left-align {
  text-align: left;
}

.filler {
  flex-grow: 1;
}

.center_icon {
  vertical-align: middle;
}

.no_padding {
  padding: 0 !important;
}

.small_padding {
  padding: 20px;
}

.small_padding_2 {
  padding: 15px;
}

.small_padding_3 {
  padding: 15px 20px;
}

.small_padding_4 {
  padding: 15px 0px;
}

.small_right_padding {
  padding-right: 20px;
}

.small_icon {
  width: 15px !important;
}

.float_right {
  float: right;
}

.float_left {
  float: left;
}

.grey_border {
  border: rgb(245, 245, 245) solid 1px;
  border-radius: 5px;
}

.black_border {
  border: #c9c9c9 solid 1px;
  border-radius: 5px;
}

.multiple_choice_box {
  line-height: 40px;
  font-family: "National", sans-serif;
  border-radius: 4px;
  text-align: center;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px;
  border-style: solid;
  border-color: gray;
}
.multiple_choice_box:hover {
  background-color: rgb(188, 188, 188);
  cursor: pointer;
}

.checkbox:hover {
  cursor: pointer;
}

.input_field {
  max-height: 30px;
}

.focused_field_custom {
  border-color: aqua;
}

.center_vertical_margin {
  margin-top: auto;
  margin-bottom: auto;
}

.center_horizontal_margin {
  margin-left: auto;
  margin-right: auto;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
  max-width: 800px;
}

.header-text ul {
  list-style: disc;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
}

.mini-title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 7px;
  letter-spacing: 1px;
}

.custom-link {
  color: #0088ce;
  text-decoration: none;
  font-weight: 800;
  border: none;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
}
.custom-link.gray {
  color: #6B6B6B;
  text-decoration: underline;
}

.error-text {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
}

.top-margin {
  margin-top: 20px !important;
}

.medium-top-margin {
  margin-top: 14px;
}

.tiny-top-margin {
  margin-top: 5px;
}

.date_input_container {
  height: auto !important;
  width: 262px !important;
  margin-left: -22.5px;
  scale: 83%;
  margin-top: -28px;
}

.date_input_container select {
  width: 100px !important;
  font-size: 13px !important;
  font-weight: bold;
  margin-top: 5px;
}

.date_input_container button {
  visibility: hidden;
}

.arial-font {
  font-family: Arial, Helvetica, sans-serif;
}

.font-24 {
  font-size: 24px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.gray-color {
  color: #333333;
}

.max-width-300 {
  max-width: 300px;
}

.max-width-600 {
  max-width: 600px;
  width: 600px;
}

.max-width-600-fit {
  max-width: 600px;
  width: -webkit-fill-available;
}

.preferred-color-bottom-line {
  border-bottom: 1px solid var(--preferred-color);
  padding-bottom: 15px;
}

.preferred-color {
  color: var(--preferred-color);
}

.footer-card {
  border: 1px solid #d7d7d7;
  border-top: 6px solid var(--preferred-color);
  border-radius: 5px;
  padding: 15px;
}

.normal-line-height {
  line-height: normal;
}

.inline {
  display: inline-block;
}

.width-50 {
  width: 50%;
}

.club-details {
  width: 100%;
  line-height: initial;
  margin-bottom: 15px !important;
}

.block-container {
  display: block;
  justify-content: center;
  margin: 25px auto;
}
.block-container strong p {
  line-height: 50px;
  font-size: xx-large;
}
@media only screen and (max-width: 768px) {
  .block-container {
    min-width: calc(100vw - 20px);
    margin-left: 10px;
    margin-right: 10px;
  }
  .block-container strong p {
    font-size: x-large;
    text-align: center;
    line-height: 30px;
  }
}

.inline-block {
  display: flex;
  align-items: center;
}

.semi-bold {
  font-weight: 600;
}

.margin-right {
  margin-right: 15px;
}

.no-decoration {
  text-decoration: none;
}

.center-text {
  text-align: center;
}

.payment-membership-title {
  max-width: 550px;
  width: -webkit-fill-available;
}

.medium-max-width {
  max-width: 650px !important;
}

.black-text {
  color: black;
}

.modal-grey-button {
  background-color: #AEAEAE !important;
  border-radius: 10px !important;
  font-size: 20px !important;
}

.no-left-padding {
  padding-left: 0 !important;
}

.auto-width {
  width: auto;
}

.create-account-box {
  max-width: 95vw;
  padding-bottom: 70px;
}

.mobile {
  display: none;
}
@media (max-width: 470px) {
  .mobile {
    display: block;
  }
}

.tablet {
  display: none;
}
@media (min-width: 470px) {
  .tablet {
    display: block;
  }
}

.hide {
  display: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: inherit;
}

html {
  overflow-y: scroll;
}

.important-full-width {
  width: 100% !important;
}

.membership_page .MuiRadio-root {
  padding-top: 3px !important;
}
.membership_page .MuiFormControlLabel-root {
  align-items: flex-start !important;
}
.membership_page .MuiFormControlLabel-root .MuiFormControlLabel-label {
  max-width: 90%;
  white-space: initial;
  word-wrap: break-word;
}
.membership_page .MuiFormControlLabel-root {
  max-width: 100%;
}
.membership_page .MuiFormControl-root {
  max-width: 100%;
}
.membership_page .panel_box {
  margin: auto;
  margin-top: 30px;
  padding: 1rem;
  background: #efefef;
  border: 1px solid #cccccc;
  width: 70%;
}
.membership_page .panel_box p {
  margin: 0 !important;
}
.membership_page p {
  font-size: 17px !important;
}
.membership_page .unavailable-membership p:nth-of-type(1), .membership_page .unavailable-membership p:nth-of-type(2) {
  font-size: 18px !important;
}
.membership_page .disabled_memberships .MuiSvgIcon-root:nth-of-type(1) {
  background-color: #868686;
  border-radius: 50%;
  height: calc(1em - 4px);
  width: calc(1em - 4px);
  border: 1px solid #000;
}
.membership_page .disabled_memberships .MuiSvgIcon-root:nth-of-type(1) path {
  fill: none;
}

.margin-auto {
  margin: auto;
}

.password .error, .confirm-password .error {
  max-width: 150px !important;
}

.password .error, .confirm-password .error {
  margin-bottom: 10px !important;
}

.medium-margin-left {
  margin-left: 10px;
}

.medium-margin-bottom {
  margin-bottom: 8px !important;
}

.center-checkbox {
  padding-top: 9px;
}

.small-font {
  font-size: small;
}

.small-left-padding {
  padding-left: 11px;
}

.display-block {
  display: block !important;
}

.large-left-right-padding {
  padding-left: 27px;
  padding-right: 27px;
}

.x-large-left-right-padding {
  padding-left: 35px;
  padding-right: 35px;
}

.unset-flex {
  flex: unset !important;
}

.receipt-table {
  width: 100%;
  border-collapse: collapse;
}
.receipt-table td, .receipt-table th {
  border: 1px solid #c9c9c9;
  padding: 10px;
}
.receipt-table th {
  text-align: left;
  width: 65%;
}
.receipt-table td {
  text-align: center;
}
.receipt-table tr:last-child th {
  text-align: right !important;
}

.box-header {
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 95vw;
  justify-content: center;
  padding: 15px;
  background: #00365f;
  color: #fff;
  border-radius: 5px;
  margin-bottom: -10px;
  position: relative;
  font-size: 20px;
}

.white-container {
  background-color: white;
  width: calc(100% - 40px);
  margin: 20px 10px;
  padding: 20px 10px;
}

.logos-container {
  width: calc(100% - 20px);
  margin: 20px 10px;
  padding: 0px 10px;
  display: flex;
  margin-top: 5px;
}

.logos-container .white-logos-container {
  background-color: white;
  margin: 20px 10px;
  padding: 20px 10px;
  margin: auto;
}

.white-logos-container .logos {
  width: 100%;
  display: flex;
}

.logos .usga-logo {
  background-image: url("https://hcp2020-stage.s3.amazonaws.com/usga-logo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 85px;
  min-height: 15px;
  margin-top: 15px;
}

.logos .usga-text {
  color: #00365f;
  font-size: 11px;
  line-height: 1.2;
  font-weight: 700;
  padding-left: 10px;
  margin-top: 15px;
}

.logos .sentry-logo {
  background-image: url("https://hcp2020-stage.s3.amazonaws.com/sentry-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  height: 50px;
  margin-left: 30px;
}

.download-app-container {
  width: calc(100% - 20px);
  margin: 20px 10px;
  padding: 20px 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
}

.download-app-container .white-mobile-container {
  background-color: white;
  margin: 20px 10px;
  padding: 20px 10px;
  padding-bottom: 0;
  margin: auto;
}

.white-mobile-container .title {
  text-align: center;
  font-size: 18px !important;
  margin-bottom: 10px;
  font-weight: bold;
}

.white-mobile-container .logos {
  width: 100%;
  display: flex;
}

.logos .apple-logo {
  background-size: contain;
  background-image: url("https://hcp2020-stage.s3.amazonaws.com/apple-store-logo.svg");
  background-repeat: no-repeat;
  width: 130px !important;
  height: 40px !important;
  margin-left: auto;
}

.logos .google-logo {
  background-size: contain;
  background-image: url("https://hcp2020-stage.s3.amazonaws.com/google-play-logo.svg");
  width: 140px !important;
  background-repeat: no-repeat;
  height: 40px;
  margin-left: 3px;
  margin-right: auto;
}

.margin-auto {
  margin: auto;
}

.space {
  height: 20px;
}

.small-space {
  height: 10px;
}

@media only screen and (min-width: 776px) and (max-width: 900px) {
  .white-mobile-container .title {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 776px) {
  .white-mobile-container .title {
    margin-bottom: 30px;
  }
  .download-app-container .white-mobile-container {
    padding: 10px 10px !important;
  }
  .logos-container .white-logos-container {
    padding: 0 10px !important;
  }
  .payment-membership-title {
    padding: 0;
  }
  .download-app-container .logos {
    margin-top: -20px !important;
  }
  .logos-container .logos {
    flex-wrap: wrap;
  }
  .usga-text {
    display: none;
  }
  .create-account-box {
    padding-top: 30px !important;
    padding-bottom: 45px !important;
  }
  .create-account-box .create_input input {
    width: 100% !important;
  }
  .logos .google-logo {
    width: 100px !important;
    margin-left: 8px;
  }
  .logos .apple-logo {
    width: 90px !important;
  }
  .logos .usga-logo {
    width: 81px !important;
  }
  .logos .sentry-logo {
    width: 83px !important;
  }
}
.white-box {
  background: #fff;
  margin: auto;
  max-width: 95vw;
  margin-top: -30px;
  position: relative;
  border-radius: 5px;
  z-index: 99;
  border: 1px solid #dedede;
}

.tiny-padding-top {
  padding-top: 4px !important;
}

.break-word {
  word-wrap: break-word;
}

.existing-golfer-button {
  background-color: #AEAEAE !important;
  color: white !important;
  width: 35vw !important;
  max-width: 250px;
  min-width: 125px;
  border-radius: 10px !important;
}

.line-height-18 {
  line-height: 18px !important;
}

.grecaptcha-badge {
  display: none;
}

.page-title {
  font-weight: bold;
  background-color: #e8e8e8;
  padding: 13px;
  margin: 0 10px;
  text-align: center;
  width: 100%;
}

.pending-approval {
  color: red;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.codes-display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.codes-display-c {
  display: flex;
  flex-direction: column;
}

.original-fee {
  color: #6c757d;
  white-space: nowrap;
  font-style: italic;
  line-height: 15px;
  font-size: 0.9em;
}

.discount-code-text {
  color: red;
  font-style: italic;
  white-space: nowrap;
  font-size: 0.9em;
  top: 33px;
}

.promotion-code-text {
  color: red;
  white-space: nowrap;
  font-size: 0.9em;
  top: 33px;
}

.promo_code_col span.error {
  max-width: 300px !important;
}

@media screen {
  .btn {
    flex: none;
    width: auto;
    height: auto;
    font-size: 15px;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 2px;
    font-family: inherit;
    padding: 0.75em 2.4em;
    border-radius: 20px;
    border: none;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    will-change: background-color;
    -webkit-transition: background-color 300ms ease-in-out 0ms;
    -moz-transition: background-color 300ms ease-in-out 0ms;
    -ms-transition: background-color 300ms ease-in-out 0ms;
    transition: background-color 300ms ease-in-out 0ms;
    -webkit-tap-highlight-color: transparent;
  }
}
@media screen and (min-width: 768px) {
  .btn {
    font-size: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .btn {
    font-size: 19px;
  }
}
@media screen {
  .btn.loading {
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/loader.svg");
  }
}
.btn.fit {
  width: auto;
  line-height: 1.25em;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 550;
  text-transform: none;
  padding: 12.5px;
}
.btn.centered_btn {
  margin: auto;
  display: block;
}
.btn.xx-smaller {
  width: 100px;
  line-height: 1.25em;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 550;
  text-transform: none;
  padding: 12.5px;
}
.btn.x-smaller {
  width: 150px;
  line-height: 1.25em;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 550;
  text-transform: none;
  padding: 12.5px;
}
.btn.smaller {
  width: 200px;
  line-height: 1.25em;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 550;
  text-transform: none;
  padding: 12.5px;
}
.btn.med {
  width: 250px;
  line-height: 1.25em;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 550;
  text-transform: none;
  padding: 12.5px;
}
.btn.x-med {
  width: 230px;
  line-height: 1.25em;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 550;
  text-transform: none;
  padding: 12.5px;
}
@media screen {
  .btn.empty {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
  }
}
@media screen {
  .btn.blank {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media screen {
  .btn.blank.lh24 {
    line-height: 24px;
    font-size: 16px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
    padding-right: 0;
  }
}
@media screen {
  .btn.blank.medium {
    font: 400 11px system-ui;
    text-transform: none;
    line-height: 15px;
    height: 15px;
  }
}
@media screen {
  .btn.blank.no-pad {
    padding: 0;
  }
}
@media screen {
  .btn.blank.blue {
    color: #00365f;
    text-transform: none;
  }
}
@media screen {
  .btn.fill.gray {
    color: #fff;
    background-color: #acacac;
  }
}
@media screen {
  .btn.fill.gray:hover {
    background-color: rgb(127.5, 127.5, 127.5);
  }
}
@media screen {
  .btn.fill.cardinal {
    color: #fff;
    background-color: #cc233c;
  }
}
@media screen {
  .btn.fill.cardinal:hover {
    background-color: rgb(182.2343096234, 31.2656903766, 53.5983263598);
  }
}
@media screen {
  .btn.fill.blue {
    color: #fff;
    background-color: #00365f;
  }
}
@media screen {
  .btn.fill.blue:hover {
    background-color: rgb(0, 39.5052631579, 69.5);
  }
}
.btn.fill.blue.sixty {
  min-width: 60%;
}
@media screen {
  .btn.fill.black {
    color: #fff;
    background-color: #000;
  }
}
@media screen {
  .btn.fill.black:hover {
    background-color: rgb(25.5, 25.5, 25.5);
  }
}
@media screen {
  .btn.fill.gray {
    color: #000;
    background-color: #e8e8e8;
  }
}
@media screen {
  .btn.fill.gray:hover {
    background-color: rgb(206.5, 206.5, 206.5);
  }
}
.btn.fill.square {
  border-radius: 0px;
}
@media screen {
  .btn.fill.white-red {
    color: #000;
    background-color: #fff;
    border: 2.5px solid #cc233c;
  }
}
@media screen {
  .btn.fill.white-red:hover {
    background-color: rgb(229.5, 229.5, 229.5);
  }
}
@media screen {
  .btn:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
@media screen {
  .btn.outline {
    display: block;
    border: none;
    will-change: box-shadow;
  }
}
.btn.outline.full {
  width: 100%;
}
@media screen {
  .btn.outline.blue {
    color: #00365f;
    -webkit-box-shadow: inset 0 0 0 1px #00365f;
    -moz-box-shadow: inset 0 0 0 1px #00365f;
    -ms-box-shadow: inset 0 0 0 1px #00365f;
    box-shadow: inset 0 0 0 1px #00365f;
    background-color: transparent;
  }
}
@media screen {
  .btn.lnk {
    font-size: 16px;
    line-height: 35px;
    font-weight: 500;
    background-color: transparent;
    text-decoration: underline;
    text-transform: none;
    letter-spacing: normal;
  }
}
@media screen {
  .btn.lnk.white {
    color: #fff;
  }
}
@media screen {
  .btn.lnk.blue {
    color: #0067e6;
  }
}
@media screen {
  .btn.lnk.no-pad {
    padding: 0;
  }
}
@media screen {
  .btn.lnk.small {
    font-size: 14px;
    line-height: 20px;
  }
}
.btn.zero-pad {
  padding-left: 0;
  padding-right: 0;
}
.btn.no-underline {
  text-decoration: none;
}
.btn.too_large_on_phone {
  padding: 0.75em 1em;
}
@media screen and (min-width: 768px) {
  .btn.too_large_on_phone {
    padding: 0.75em 2.4em;
  }
}
.btn.has_badge {
  padding: 0.5em 2.4em;
}
.btn .badge {
  display: inline-block;
  font-size: 1.2em;
  padding: 0.25em 0.4em;
  letter-spacing: normal;
  line-height: 1em;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  background-color: #fff;
}
.btn.fill.cardinal .badge {
  color: #cc233c;
}
@media screen {
  .btn.comp {
    padding: 0;
    background-color: transparent;
    border: none;
  }
}
@media screen {
  .btn.comp .btn-in {
    padding: 0 10px;
    margin: 0 5px 0 0;
    height: 30px;
    line-height: 30px;
    color: #0088ce;
    background-color: #f6f6f6;
  }
}
@media screen {
  .btn.comp .btn-out {
    color: #00365f;
  }
}
.btn.comp .open {
  display: none;
}
.btn.comp .close {
  display: inline-block;
}
.btn.comp.active .open {
  display: inline-block;
}
.btn.comp.active .close {
  display: none;
}
.btn.add, .btn.delete, .btn.check {
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.btn.add::before, .btn.delete::before, .btn.check::before {
  content: "";
  display: block;
  margin: 0 5px 0 0;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
}
.btn.add {
  color: #00365f;
  background-color: transparent;
  border: none;
}
.btn.add::before {
  background-image: url("../assets/img/icons/ic_circle_plus.svg");
}
.btn.delete {
  color: #cc233c;
  background-color: transparent;
  border: none;
  padding: 0;
}
.btn.delete::before {
  background-image: url("../assets/img/icons/ic_circle_minus.svg");
}
.btn.check {
  color: #00365f;
  background-color: transparent;
  border: none;
}
.btn.check::before {
  background-image: url("../assets/img/icons/ic_circle_plus.svg");
}
@media screen {
  .btn.search {
    color: #fff;
    background-color: #000;
    margin-left: 5px;
    padding: 0;
    border-radius: 0 5px 5px 0;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
  }
}
@media screen {
  .btn .material-icons-outlined {
    width: 25px;
    height: 25px;
    line-height: 32px;
    text-align: center;
  }
}

.item-list.courses {
  position: relative;
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__action {
    position: absolute;
    top: 0;
    right: 20px;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .item-list.courses .item-list__action .btn.lnk.type-z-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 200px;
    height: 55px;
    white-space: normal;
    text-align: right;
  }
}
@media screen and (min-width: 1280px) {
  .item-list.courses .item-list__action .btn.lnk.type-z-button {
    max-width: none;
  }
}
.item-list.courses .item-list__action .btn.lnk.type-z-button.filter {
  height: 70px;
}

@media only screen and (max-width: 768px) {
  .btn-clear {
    width: calc(100% - 20px) !important;
    margin-top: 20px !important;
  }
}
@media screen {
  .search-container {
    display: flex;
    flex-direction: column;
    flex: none;
    margin: 25px 0 auto;
    color: #73737d;
    margin-bottom: 40px;
  }
}
@media screen {
  .search-container input[type=text].search {
    flex: none;
    width: 300px;
    border: 1px solid #cccccc;
    border-radius: 5px 0 0 5px;
    font-size: 20px;
    padding-left: 5px;
  }
}
.search-container .titles-container {
  margin: 10px 0 0 -16px;
}
.search-container .buttons-container {
  margin: 10px 0 0 -13px;
}
.search-container .radio-item,
.search-container .checkbox-item {
  display: inline-block;
  position: relative;
}
.search-container .radio-item input[type=radio],
.search-container .checkbox-item input[type=checkbox] {
  display: none;
}
.search-container .radio-item label:before,
.search-container .checkbox-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
  border: 2px solid #cccccc;
  background-color: transparent;
  cursor: pointer;
}
.search-container .radio-item label:before {
  top: 2px;
  left: 2px;
  margin: 0 5px 0 0;
  border-radius: 11px;
}
.search-container .checkbox-item label:before {
  top: 2px;
  left: 5px;
  margin: 0 8px 0 0;
}
.search-container .radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 6px;
  content: " ";
  display: block;
  background: black;
}
.search-container .radio-item:first-child,
.search-container .checkbox-item:first-child {
  margin-right: 10px;
}
.search-container .radio-item label,
.search-container .checkbox-item label {
  cursor: pointer;
}
.search-container .checkbox-item input[type=checkbox]:checked + label:after {
  content: "✔";
  position: absolute;
  top: 5px;
  left: 8px;
  font-size: 10px;
  color: black;
  cursor: pointer;
}
.search-container .big-margin-left {
  margin-left: 26px;
}
.search-container .label-in-row {
  margin-right: 25px;
  padding-top: 1px;
}
.search-container .middle-align {
  align-items: center;
}

.infinite-scroll {
  left: -180px;
  position: relative;
  width: 710px;
  padding: 10px;
}

.club-container {
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  min-height: 70px;
  min-width: 750px;
  max-width: 750px;
}
@media only screen and (max-width: 768px) {
  .club-container {
    width: 100%;
    min-width: calc(100vw - 20px);
    flex-direction: column;
  }
}
.club-container .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  border-left: 1px solid #cccccc;
}
@media only screen and (max-width: 768px) {
  .club-container .btn-container {
    width: 100%;
    border-left: 0px;
    border-top: 1px solid #cccccc;
    padding: 10px;
  }
}
.club-container .btn-container .join-btn {
  width: 60%;
  padding: 0.7em 2em;
  font-size: 13px;
  font-weight: 700;
  border-radius: 20px;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
}
.club-container .info-container {
  width: 80%;
  padding: 15px 20px 12px 28px;
}
@media only screen and (max-width: 768px) {
  .club-container .info-container {
    width: 100%;
  }
}
.club-container .info-container div.row {
  margin-bottom: 5px;
}
.club-container .info-container div p:first-child {
  align-items: flex-start;
}
.club-container .info-container div p:last-child,
.club-container .info-container div a:last-child {
  align-items: flex-end;
}
.club-container .info-container .title-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  text-align: left;
}
.club-container .info-container .title-container .club-title {
  color: #73737d;
  font-size: 18px;
  font-weight: 700;
}
.club-container .info-container .more-info {
  display: flex;
  justify-content: flex-end;
  width: 25%;
  padding: 0;
  margin-left: 10px;
  margin: auto 10px;
  cursor: pointer;
  border: none;
  color: #0088ce;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
}
.club-container .info-container .more-info .expand-icon {
  font-size: 18px;
}
.club-container .info-container .address {
  color: #73737d;
  font-size: 14px;
  font-weight: 500;
  padding-left: 0;
}
.club-container .info-container .fee-container {
  display: flex;
  justify-content: flex-end;
}
.club-container .info-container .fee-container .fee-text {
  color: #73737d;
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}
.club-container .info-container .fee-container .fee-value {
  color: #73737d;
  font-size: 14px;
  font-weight: 700;
}
.club-container .info-container .link:link,
.club-container .info-container .link:visited {
  display: flex;
  flex-direction: row;
  color: #0088ce;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  padding: 0;
}
.club-container .info-container .link-icon {
  font-size: 14px;
}
.club-container .info-container .info-message {
  margin: 25px auto 10px 0;
  text-align: left;
  font-size: 14px;
  color: #73737d;
  line-height: 1.25em;
}
.club-container .fees-table {
  width: 100%;
  border-collapse: collapse;
  color: #73737d;
}
.club-container .fees-table td, .club-container .fees-table th {
  border: 1px solid #c9c9c9;
  padding: 10px;
}
.club-container .fees-table th {
  text-align: left;
  width: 50%;
}
.club-container .fees-table th.name {
  text-align: right;
}
.club-container .fees-table td {
  text-align: center;
}

.discount-box {
  margin-left: 10px;
  background-color: #ffffff;
  border: 2px solid #28a745;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.success-icon {
  color: #28a745;
}

.remove-button {
  background-color: transparent;
  color: #6c757d;
  text-decoration: underline;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.discount-box-hidden {
  justify-content: initial;
}
.discount-box-hidden .discount-details {
  margin: auto;
  transform: translateX(-29.6665px);
}

.box-panel {
  background-color: #fff;
  padding: 20px;
}
.box-panel.center {
  justify-content: center;
  display: flex;
}
.box-panel.no-margins {
  padding: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .box-panel {
    padding: 30px 30px;
  }
  .box-panel.no-margins {
    padding: 0;
    padding: 0;
  }
}
@media screen and (min-width: 1280px) {
  .box-panel {
    padding: 25px 50px;
  }
  .box-panel.no-margins {
    padding: 0;
    padding: 0;
  }
}
.container {
  max-width: 470px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .center {
  text-align: center;
  align-self: center;
  justify-content: center;
  align-items: center;
}
.container .left {
  align-self: flex-start;
}
.container .row-display {
  display: flex;
  flex-direction: column;
}
.container .row-display span {
  padding-bottom: 2px;
}
.container .col-display {
  display: flex;
  flex-direction: row;
}
.container .bottom-actions {
  display: flex;
  flex-direction: row;
  margin-top: 2.5em;
  margin-bottom: 2em;
  justify-content: center;
}
.container .note {
  font-size: 16px;
  line-height: 1.2em;
  font-family: "National", sans-serif;
  max-width: 450px;
  text-align: center;
}
.container .note a {
  text-decoration: underline;
}
.container .note.gray {
  color: #6b6b6b;
}
.container .note.gray a {
  color: #6b6b6b;
}
.container .success-icon {
  height: 50px;
  background: url("../assets/img/icons/check_icon.svg") no-repeat center;
  margin-top: 2em;
}
.container .success-title {
  color: #00365f;
  text-align: center;
  font-size: 1.4em;
  margin-top: 1.5em;
  margin-bottom: 2.5em;
}
.container .row .col-sm.two {
  margin-left: 34%;
}
@media screen and (max-width: 768px) and (min-width: 481px) {
  .container .row .col-sm.two {
    margin-left: 32%;
  }
}
@media screen and (max-width: 480px) {
  .container .row .col-sm.two {
    margin-left: 28%;
  }
}
.container .row .col-sm.third {
  margin-left: 4%;
  margin-top: 4%;
  height: 5px;
  width: 500px;
}
@media screen and (max-width: 768px) and (min-width: 481px) {
  .container .row .col-sm.third {
    width: 400px;
  }
}
@media screen and (max-width: 480px) {
  .container .row .col-sm.third {
    width: 300px;
  }
}
.container .row .col-sm.third p.text-bold {
  font-style: italic;
  font-weight: bold;
}
.container .row .col-sm.third p.text-info {
  line-height: 18px;
}

.page_container {
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding: 20px 0 50px 0;
}
.page_container.contact {
  max-width: 830px;
}
@media screen and (min-width: 768px) {
  .page_container {
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .page_container {
    padding: 30px 0;
  }
}
.page_container input::-ms-reveal,
.page_container input::-ms-clear {
  display: none;
}
.page_container h1 {
  color: #00365f;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 18px;
}
@media screen and (min-width: 768px) {
  .page_container h1 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1280px) {
  .page_container h1 {
    font-size: 35px;
  }
}
.page_container h3 {
  font-size: 25px;
  line-height: 1em;
}
@media screen and (min-width: 768px) {
  .page_container h3 {
    font-size: 28px;
  }
}
.page_container p,
.page_container select,
.page_container option,
.page_container textarea,
.page_container label {
  font-size: 16px;
  border-color: #e5e5e5;
}
.page_container input[type=text].large,
.page_container input[type=password].large,
.page_container input[type=email].large {
  font-size: 16px;
  border-color: #e5e5e5;
}
.page_container input[type=text].large:focus,
.page_container input[type=password].large:focus,
.page_container input[type=email].large:focus {
  border-color: #e5e5e5;
}
.page_container input[type=text].large.error,
.page_container input[type=password].large.error,
.page_container input[type=email].large.error {
  border-color: #ca243e;
}
@media screen and (min-width: 1280px) {
  .page_container p,
  .page_container select,
  .page_container option,
  .page_container textarea,
  .page_container label {
    font-size: 18px;
  }
}
.page_container p {
  margin: 1em 0;
  line-height: 1.5em;
  font-family: "National", sans-serif;
}
.page_container .top-note {
  margin-top: 0;
  color: #73737d;
  font-size: 90%;
  font-style: italic;
  font-weight: 400;
  position: relative;
  top: 10px;
}
.page_container .help-text {
  margin: 0;
  font-size: 88%;
  color: #73737d;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
}
.page_container .help-text span {
  display: inline-block;
  min-width: 40px;
}
.page_container .text-muted {
  color: #73737d;
}
.page_container .box-content {
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .page_container .box-content {
    padding: 0;
  }
}
.page_container .box-panel {
  background-color: #fff;
  padding: 20px;
}
.page_container .box-panel.center {
  justify-content: center;
  display: flex;
}
.page_container .box-panel.no-margins {
  padding: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  .page_container .box-panel {
    padding: 30px 30px;
  }
  .page_container .box-panel.no-margins {
    padding: 0;
    padding: 0;
  }
}
@media screen and (min-width: 1280px) {
  .page_container .box-panel {
    padding: 25px 50px;
  }
  .page_container .box-panel.no-margins {
    padding: 0;
    padding: 0;
  }
}
.page_container .video_wrapper {
  position: relative;
  padding: 28% 0 28% 0;
  height: 0;
}
.page_container .video_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page_container .form-row {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.page_container .form-row .form-group {
  flex: 1 0 auto;
  padding: 12px 0;
  position: relative;
}
.page_container .form-row .form-group span.error {
  position: absolute;
  bottom: -17px;
  left: 0;
  color: #ca243e;
  font-style: italic;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .page_container .form-row {
    flex-direction: row;
    padding: 12px 0;
  }
  .page_container .form-row .form-group {
    flex: 1 0 0;
    padding: 0 5px;
  }
  .page_container .form-row .form-group:first-child {
    padding-left: 0;
  }
  .page_container .form-row .form-group:last-child {
    padding-right: 0;
  }
}
.page_container .buttons-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  max-width: 380px;
  margin: 0 auto;
}
.page_container .buttons-row .lnk {
  text-decoration: none;
  padding-left: 0;
}
.page_container .buttons-row .cardinal {
  flex-grow: 1;
}
.page_container label {
  color: inherit;
  font-weight: 400;
}
.page_container textarea {
  border: 1px solid #e5e5e5;
  width: 100%;
  padding: 20px;
}
.page_container .box-content.fix-right-links {
  padding: 0 50px 0 20px;
}
@media screen and (min-width: 1280px) {
  .page_container .box-content.fix-right-links {
    padding: 0;
  }
}
.page_container.about .logo_header {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
}
.page_container.about .logo_header .ghin {
  display: flex;
  align-items: flex-end;
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 40px;
}
.page_container.about .logo_header .ghin {
  font-size: 24px;
  color: #00365f;
  font-weight: 300;
}
@media screen and (min-width: 768px) {
  .page_container.about .logo_header {
    padding: 30px 0;
  }
  .page_container.about .logo_header .ghin {
    background-size: auto 40px;
    padding-right: 125px;
    font-size: 28px;
  }
}
@media screen and (min-width: 1280px) {
  .page_container.about .logo_header .ghin {
    background-size: auto 40px;
    padding-right: 130px;
    font-size: 34px;
  }
}
.page_container.about .section_links {
  padding: 15px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.page_container.about .section_links .tabs.light {
  margin: 0;
}
.page_container.about h3 {
  color: #00365f;
  margin-bottom: 20px;
}
.page_container.about p {
  line-height: 2em;
}
.page_container.about .bigstart::first-letter {
  font-size: 200%;
}
@media screen and (min-width: 768px) {
  .page_container.about .pos-rlt .socials {
    right: 20px;
    bottom: 20px;
  }
  .page_container.about .pos-rlt .socials a.share,
  .page_container.about .pos-rlt .socials button.share {
    display: none;
  }
  .page_container.about .pos-rlt .socials a.twitter, .page_container.about .pos-rlt .socials a.facebook, .page_container.about .pos-rlt .socials a.linkedin,
  .page_container.about .pos-rlt .socials button.twitter,
  .page_container.about .pos-rlt .socials button.facebook,
  .page_container.about .pos-rlt .socials button.linkedin {
    display: block;
  }
}
@media screen and (min-width: 1280px) {
  .page_container.about .pos-rlt .socials a,
  .page_container.about .pos-rlt .socials button {
    width: 40px;
    height: 40px;
    background-size: auto 17px;
  }
}
.page_container.create-profile-page h4 {
  color: #4c7b93;
  margin-bottom: 20px;
  margin-top: -10px;
}

.create_input {
  margin-top: 5px;
}
.create_input input[type=text],
.create_input input[type=email],
.create_input input[type=date],
.create_input input[type=number],
.create_input input[type=password],
.create_input select {
  font-family: "National", sans-serif;
  border-radius: 1.5px;
  font-size: 18px;
  height: 43px;
  width: 225px;
  border: solid 1px #666666;
  padding: 5px 10px;
}
.create_input.no-margin {
  margin-top: 0px;
}
.create_input.fit input[type=text],
.create_input.fit input[type=email],
.create_input.fit input[type=date],
.create_input.fit input[type=number],
.create_input.fit input[type=password],
.create_input.fit select {
  width: auto;
}
.create_input.small input[type=text],
.create_input.small input[type=email],
.create_input.small input[type=date],
.create_input.small input[type=number],
.create_input.small input[type=password],
.create_input.small select {
  width: 75px;
}
.create_input.medium-small input[type=text],
.create_input.medium-small input[type=email],
.create_input.medium-small input[type=date],
.create_input.medium-small input[type=number],
.create_input.medium-small input[type=password],
.create_input.medium-small select {
  width: 150px;
}
.create_input.medium input[type=text],
.create_input.medium input[type=email],
.create_input.medium input[type=date],
.create_input.medium input[type=number],
.create_input.medium input[type=password],
.create_input.medium select {
  width: 180px;
}
.create_input.medium-big input[type=text],
.create_input.medium-big input[type=email],
.create_input.medium-big input[type=date],
.create_input.medium-big input[type=number],
.create_input.medium-big input[type=password],
.create_input.medium-big select {
  width: 250px;
}
.create_input.almost-big input[type=text],
.create_input.almost-big input[type=email],
.create_input.almost-big input[type=date],
.create_input.almost-big input[type=number],
.create_input.almost-big input[type=password],
.create_input.almost-big select {
  width: 300px;
}
.create_input.big input[type=text],
.create_input.big input[type=email],
.create_input.big input[type=date],
.create_input.big input[type=number],
.create_input.big input[type=password],
.create_input.big select {
  width: 350px;
}
.create_input.is-error input[type=text],
.create_input.is-error input[type=email],
.create_input.is-error input[type=date],
.create_input.is-error input[type=number],
.create_input.is-error input[type=password],
.create_input.is-error select {
  border: solid 1px #da1a31;
}
.create_input.pedding-lr-10px {
  padding-left: 10px;
  padding-right: 10px;
}
.create_input label {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 7px;
  font-family: National, sans-serif;
  font-size: 14px;
  font-weight: 600;
}
.create_input label.disable {
  margin-top: -10px;
  font-family: National, sans-serif;
  font-size: 18px;
}
.create_input label.terms {
  font-family: National, sans-serif;
  font-size: 16px;
}
.create_input span.error {
  color: red;
  font-size: 15px;
  line-height: 15px;
  font-family: National, sans-serif;
  margin-top: 8px;
  max-width: 225px;
}
.create_input span.error.small {
  max-width: 75px;
}
.create_input span.error.big {
  max-width: 400px;
}
.create_input input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  opacity: 0;
  width: 100%;
}
.create_input input[type=date]:before {
  content: attr(value);
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .create_input.responsive-on-tablet {
    width: 100%;
    margin-top: 15px;
  }
  .create_input.responsive-on-tablet.small input[type=text],
  .create_input.responsive-on-tablet.small input[type=email],
  .create_input.responsive-on-tablet.small input[type=date],
  .create_input.responsive-on-tablet.small input[type=number],
  .create_input.responsive-on-tablet.small input[type=password],
  .create_input.responsive-on-tablet.small select {
    width: 100%;
  }
  .create_input.responsive-on-tablet.medium input[type=text],
  .create_input.responsive-on-tablet.medium input[type=email],
  .create_input.responsive-on-tablet.medium input[type=date],
  .create_input.responsive-on-tablet.medium input[type=number],
  .create_input.responsive-on-tablet.medium input[type=password],
  .create_input.responsive-on-tablet.medium select {
    width: 100%;
  }
}

.select_box {
  border: 1px solid black;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 115px;
  min-width: 100px;
}
.select_box span {
  color: #6b6b6b;
  position: absolute;
  bottom: 15px;
}
.select_box i {
  font-size: 50px;
  color: #6b6b6b;
  font-weight: 900;
  display: table;
  margin-top: 25%;
}

.radio_box {
  border: solid 1px #666666;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 112.5px;
}
.radio_box span {
  color: #6b6b6b;
}
.radio_box span.clicked {
  color: white;
}
.radio_box.clicked {
  background-color: #da1a31;
  border: none;
}

@media screen {
  .loader {
    width: 120px;
    height: 40px;
    display: inline-block;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: initial;
    background-image: url("../img/loader.svg");
  }
}

@media screen {
  .first-page-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen {
  .first-page-container .header-text {
    width: 100%;
    line-height: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .first-page-container .filter-container {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
  }
}
.first-page-container .filter-container .left-right-margin {
  margin-left: 20px;
  margin-right: 20px;
}
.first-page-container .filter-container {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 0 25px 30px;
  margin-bottom: 0px !important;
}
@media only screen and (max-width: 768px) {
  .first-page-container .translate-y {
    transform: none;
    margin-top: 10px;
  }
}
.first-page-container .translate-y {
  transform: translateY(14px);
  margin: auto;
}
@media only screen and (min-width: 768px) {
  .first-page-container .text-under {
    position: relative;
    display: block;
  }
  .first-page-container .text-under p {
    line-height: 0;
    padding-left: 10px;
    margin-top: 10px;
    position: absolute;
    width: 400px;
  }
}
@media only screen and (max-width: 768px) {
  .first-page-container .text-under {
    width: 100%;
  }
  .first-page-container .text-under p {
    position: relative;
    padding-left: 10px;
    line-height: 18px;
    margin-top: 0;
  }
}
.first-page-container .infinite-scroll-component__outerdiv {
  width: 100%;
  padding: 10px;
}
.first-page-container .infinite {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  margin-top: 20px;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100%;
  min-width: auto;
}

.minw-auto {
  min-width: auto;
}

.no_margin {
  margin: 0 !important;
}

@media (max-width: 450px) {
  .label-fix {
    margin-bottom: 21px !important;
  }
}
@media (min-width: 510px) {
  .container-set-width {
    width: 450px !important;
  }
}
.state-zip {
  flex-direction: row !important;
}

.address_page .p-0, .guardian_page .p-0, .golfer_page .p-0 {
  padding: 0 !important;
}
.address_page .city-col .create_input, .guardian_page .city-col .create_input, .golfer_page .city-col .create_input {
  margin-right: 10px;
}
@media (max-width: 768px) {
  .address_page .row, .guardian_page .row, .golfer_page .row {
    flex-direction: column;
  }
  .address_page .row .is-1-of-2, .guardian_page .row .is-1-of-2, .golfer_page .row .is-1-of-2 {
    width: 100% !important;
  }
  .address_page .row .is-1-of-2 input[type=text], .address_page .row .is-1-of-2 select, .guardian_page .row .is-1-of-2 input[type=text], .guardian_page .row .is-1-of-2 select, .golfer_page .row .is-1-of-2 input[type=text], .golfer_page .row .is-1-of-2 select {
    width: 100%;
  }
  .address_page .row .radio_box, .guardian_page .row .radio_box, .golfer_page .row .radio_box {
    width: 50%;
  }
  .address_page .city-col .create_input, .guardian_page .city-col .create_input, .golfer_page .city-col .create_input {
    margin-right: 0 !important;
  }
}

